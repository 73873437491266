import { Application } from '@amc-technology/applicationangularframework';
import {
  CHANNEL_TYPES,
  IAppConfiguration,
  IInteraction,
  INTERACTION_DIRECTION_TYPES,
  INTERACTION_STATES,
  INTERCEPTOR_TIMEOUT_ACTION,
  ISearchLayoutForEntity,
  MULTI_MATCH_POP_TYPES,
  NOTIFICATION_TYPE,
  NO_MATCH_POP_TYPES,
  OPERATIONS,
  RecordItem,
  SINGLE_MATCH_POP_TYPES,
  SearchLayout,
  SearchLayouts,
  SearchRecords,
  clickToDial,
  registerForFrameworkNotifications,
  registerOnLogout,
  registerOpenMyCallsToday,
  registerOperationInterceptor,
  sendNotification
} from '@amc-technology/davinci-api';
import { Component, OnInit, ViewChild } from '@angular/core';
import { bind } from 'bind-decorator';
import { LoggerService } from '../logger.service';
import { IActivity } from '../Model/Interfaces/IActivity';
import { IActivityDetails } from '../Model/Interfaces/IActivityDetails';
import { ICreateNewSObjectParams } from '../Model/Interfaces/ICreateNewSObjectParams';
import { StorageService } from '../storage.service';
import { CUSTOM_OBJECT_ASSOCIATION_RESULT } from '../Model/Interfaces/ICustomAssociationResult';
import { ERROR_CODE, IRequest, IResponse, LOG_LEVEL } from '@amc-technology/davinci-api/dist/HelperFunctions';
import { ModifiedNumber } from '../Model/Classes/ModifiedNumber';
import { Subject } from 'rxjs';
import { ActivitySalesforceComponent } from '../activity/activity-salesforce.component';
import { SF_BRIDGE_EVTS } from '../Model/Classes/SalesforceBridgeEvents';
import { IHvsCompleteWorkItemPayload, IHvsNewWorkItem } from '../Model/Interfaces/IOpenCtiSdk';
import { DaVinciHvsWorkItem } from '../Model/Classes/DaVinciHvsWorkItem';
@Component({
  selector: 'app-home',
  templateUrl: './home-salesforce.component.html'
})
export class HomeSalesforceComponent extends Application implements OnInit {
  log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) => void;

  protected phoneNumberFormat: Object;
  public clickToDialPhoneReformatMap: Object;
  protected quickCommentList: string[];
  protected enableAutoSave: boolean;
  protected enableDiscard: boolean;
  protected autoSaveTimer: number;
  public enableCallActivity: boolean;
  protected QuickCreateEntities: any;
  protected cadActivityMap: Object;
  public searchLayout: SearchLayouts;
  public activityLayout: any;
  screenpopOnAlert: Boolean;
  clickToDialList: {
    [key: string]: any;
  };

  ctdWhoWhatList: {
    [key: string]: any;
  };

  public isCollapsed = false;

  lastOnFocusWasAnEntityList: string[];
  ScreenpopOnClickToDialListView: boolean;
  DisplayQuickCreate: boolean;
  public quickCommentOptionRequiredCadArray: any;
  public isInConsoleView: boolean;

  private activityExpirationTime: number;
  private activityVerificationFrequency: number;
  private switchToLastActiveScenario: boolean;
  private delayActivitySave: number;
  private delayActivitySavePerChannel: {
    [key: number]: number;
  };

  private apiToProperName: Object;
  private objectPrefixMap = {
    '001': 'Account',
    '002': 'Note',
    '003': 'Contact',
    '005': 'User',
    '006': 'Opportunity',
    '007': 'Activity',
    '00Q': 'Lead',
    '00T': 'Task'
  };

  private relatedFieldNames: Object;
  private enableCustomAssociation = false;

  private isFloating = false;

  private phoneNumberToModifiedNumber: Map<string, ModifiedNumber>;
  private curHvsWorkItem?: DaVinciHvsWorkItem;

  clickSubject: Subject<any> = new Subject();
  @ViewChild(ActivitySalesforceComponent) child: ActivitySalesforceComponent;

  constructor(private loggerService: LoggerService, public storageService: StorageService) {
    super(loggerService.logger);
    this.log = this.loggerService.log;
    this.phoneNumberFormat = {};
    this.clickToDialPhoneReformatMap = {};
    this.screenpopOnAlert = true;
    this.ScreenpopOnClickToDialListView = false;
    this.enableCallActivity = true;
    this.enableAutoSave = true;
    this.enableDiscard = false;
    this.autoSaveTimer = 10;
    this.DisplayQuickCreate = false;
    this.clickToDialList = {};
    this.ctdWhoWhatList = {};
    this.lastOnFocusWasAnEntityList = [];
    this.quickCommentOptionRequiredCadArray = {};
    this.isInConsoleView = true;
    this.activityVerificationFrequency = 5000;
    this.activityExpirationTime = 10000;
    this.delayActivitySave = 0;
    this.delayActivitySavePerChannel = {};
    this.switchToLastActiveScenario = true;
    this.phoneNumberToModifiedNumber = new Map<string, ModifiedNumber>();
  }

  async ngOnInit() {
    try {
      await this.loadConfig();
      let salesforceOrg = 'https://na53.salesforce.com';
      if (this.appConfig['variables']['salesforceOrg'] !== undefined && this.appConfig['variables']['salesforceOrg'] !== null && String(this.appConfig['variables']['salesforceOrg']).length > 0) {
        salesforceOrg = String(this.appConfig['variables']['salesforceOrg']);
      }

      this.bridgeScripts = this.bridgeScripts.concat([
        this.getBridgeURL(),
        salesforceOrg + '/support/api/48.0/interaction.js',
        salesforceOrg + '/support/console/48.0/integration.js',
        salesforceOrg + '/support/api/48.0/lightning/opencti_min.js'
      ]);

      registerOpenMyCallsToday(this.openMyCallsToday);
      registerForFrameworkNotifications(this.didSelectedTabChange.bind(this));
      await super.ngOnInit();

      this.getActivityLayout();
      this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.UPDATE_ACTIVITY_LAYOUT, this.activityLayout);
      this.bridgeEventsService.subscribe(SF_BRIDGE_EVTS.CLICK_TO_DIAL, this.clickToDialHandler);
      this.bridgeEventsService.subscribe(SF_BRIDGE_EVTS.SEND_NOTIFICATION, this.sendNotification);
      this.bridgeEventsService.subscribe(SF_BRIDGE_EVTS.HVS_WORK_STARTED, this.workStarted_hvs);

      await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.PASS_CONFIG, this.appConfig);
      this.searchLayout = await this.getSearchLayout();
      await this.readConfig(this.appConfig);
      registerOnLogout(async () => {
        await this.loggerService.logger.pushLogsAsync();
        await this.removeLocalStorageOnLogout();
      });
      this.isInConsoleView = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.SET_IS_IN_CONSOLE_VIEW);
      registerOperationInterceptor(
        [OPERATIONS.CLICK_TO_DIAL, OPERATIONS.CONTEXTUAL_EVENT],
        async (payload: { didTimeout: boolean; pluginName: string; message: IRequest | IResponse }) => {
          this.handleIntercept(payload);

          return payload;
        },
        15000,
        INTERCEPTOR_TIMEOUT_ACTION.CANCEL
      );
      setInterval(this.cleanupScenarios, this.activityVerificationFrequency);
      this.storageService.syncWithLocalStorage();
      this.loggerService.logger.logDebug('Salesforce - Home : END : Fetching Salesforce App Configuration');
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Fetching Configuration. Error Information : ' + JSON.stringify(error));
    }
  }

  @bind
  workStarted_hvs(newWorkItem: IHvsNewWorkItem): void {
    const fname = 'Salesforce - Home : workStarted_hvs():';
    this.logger.logTrace(`${fname} BEGIN`);

    this.logger.logInformation(`${fname} Received new HVS work item data: ${JSON.stringify(newWorkItem)}`);

    this.curHvsWorkItem = new DaVinciHvsWorkItem(newWorkItem);

    this.logger.logTrace(`${fname} END`);
  }

  private handleIntercept(payload) {
    try {
      if (!payload.didTimeout) {
        let operation;

        if ('isResponse' in payload.message) {
          operation = payload.message.request.operation;
        } else {
          operation = payload.message.operation;
        }

        let records: any;
        if (!('isResponse' in payload.message)) {
          switch (operation) {
            case OPERATIONS.CLICK_TO_DIAL:
              records = payload.message.data[1];
              break;
            case OPERATIONS.CONTEXTUAL_EVENT:
              records = payload.message.data;
              break;
            default:
          }

          if (records[0] && records[0].fields && records[0].fields['ModifiedNumber']) {
            const modifiedNumber = new ModifiedNumber();

            modifiedNumber.updatedNumber = records[0].fields['ModifiedNumber'].Value;

            modifiedNumber.originalNumber = records[0].fields['OriginalNumber'].Value;

            if (records[0].fields['TargetedDial.Prefix.Key']) {
              if (records[0].fields['TargetedDial.Prefix.Value']) {
                modifiedNumber.prefix = {
                  key: records[0].fields['TargetedDial.Prefix.Key'].Value,
                  val: records[0].fields['TargetedDial.Prefix.Value'].Value
                };
              }
            }
            if (records[0].fields['TargetedDial.Suffix.Key']) {
              if (records[0].fields['TargetedDial.Suffix.Value']) {
                modifiedNumber.suffix = {
                  key: records[0].fields['TargetedDial.Suffix.Key'].Value,
                  val: records[0].fields['TargetedDial.Suffix.Value'].Value
                };
              }
            }

            this.phoneNumberToModifiedNumber.set(modifiedNumber.originalNumber, modifiedNumber);
            this.phoneNumberToModifiedNumber.set(modifiedNumber.updatedNumber, modifiedNumber);
          }
        }
      }
    } catch (error) {}
  }

  private findCorrectScenarioId(activeScenarioList, appName) {
    for (let i = 0; i < activeScenarioList.length; i++) {
      if (activeScenarioList[i].substring(0, appName.length) === appName) {
        return i;
      }
    }
    return 0;
  }

  private didSelectedTabChange(event: any) {
    try {
      const promise = new Promise(function (resolve, reject) {});
      // Creates an object for each CTI app loaded in the framework
      if (this.storageService.tabbedActivityList.length === 0) {
        const arr = event.apps.substring(0, event.apps.length - 1).split(',');
        for (let i = 0; i < arr.length; i++) {
          const tab = new Object();
          tab['tabName'] = arr[i];
          this.storageService.tabbedActivityList.push(tab);
        }
      }
      if (event.didTabChange === true) {
        this.storageService.tabName = event.newTabName;
        if (this.storageService.activeScenarioIdList.length > 0) {
          this.storageService.tabsObject = [];
          this.storageService.tabbedActivityList.forEach((tab) => {
            if (tab.tabName === event.newTabName) {
              this.storageService.tabsObject.push(tab.content);
            }
          });
        }

        if (this.storageService.currentScenarioId !== null) {
          if (event.newTabName !== this.storageService.currentScenarioId.substring(0, event.newTabName.length)) {
            const scenarioIndex = this.findCorrectScenarioId(this.storageService.activeScenarioIdList, event.newTabName);
            if (this.storageService.currentScenarioId !== this.storageService.activeScenarioIdList[scenarioIndex]) {
              this.storageService.setCurrentScenarioId(this.storageService.activeScenarioIdList[scenarioIndex], INTERACTION_DIRECTION_TYPES[this.storageService.activityList[this.storageService.currentScenarioId]['CallType']]);

              this.child.switchNgBTab(this.storageService.currentScenarioId);
            }
          }
        }
      }

      return promise;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Switching CTI tabs. . Error Information : ' + JSON.stringify(error));
    }
  }

  @bind
  private openMyCallsToday(url: string, openInNewWindow: boolean) {
    return this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.OPEN_MY_CALLS_TODAY, {
      url,
      openInNewWindow
    });
  }

  // eslint-disable-next-line max-statements
  private async readConfig(config: IAppConfiguration) {
    try {
      this.loggerService.logger.logDebug('Salesforce - Home : START : Reading Configuration from Salesforce App');
      const configPhoneFormat = config.variables['PhoneNumberFormat'];

      if (typeof configPhoneFormat === 'string') {
        const tempFormat = String(configPhoneFormat).toLowerCase();
        this.phoneNumberFormat[tempFormat] = tempFormat;
      } else {
        this.phoneNumberFormat = configPhoneFormat;
      }

      if (config.variables['ClickToDialPhoneReformatMap']) {
        this.clickToDialPhoneReformatMap = config.variables['ClickToDialPhoneReformatMap'];
      }

      this.quickCommentList = <string[]>(config['CallActivity'] ? config['CallActivity']['variables']['QuickComments'] : config['variables']['QuickComments']);
      this.cadActivityMap = config['CallActivity'] ? config['CallActivity']['variables']['CADActivityMap'] : config['variables']['CADActivityMap'] ? config['variables']['CADActivityMap'] : {};

      if (config['variables']['ScreenpopOnAlert'] !== null && config['variables']['ScreenpopOnAlert'] !== undefined) {
        this.screenpopOnAlert = Boolean(config['variables']['ScreenpopOnAlert']);
      }

      for (let i = 0; i < this.quickCommentList.length; i++) {
        this.quickCommentList[i] = this.quickCommentList[i].replace(/\\n/g, String.fromCharCode(13, 10));
        this.quickCommentList[i] = this.quickCommentList[i].replace(/\\t/g, String.fromCharCode(9));
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const CADQuickCommentRegex = /\{\{.*?\}\}/g;

      for (let i = 0; i < this.quickCommentList.length; i++) {
        this.quickCommentOptionRequiredCadArray[i] = this.quickCommentList[i].match(CADQuickCommentRegex);
      }

      this.QuickCreateEntities = config['QuickCreate']['variables']['QuickCreateKeyList'];
      this.DisplayQuickCreate = Object.keys(this.QuickCreateEntities).length > 0;
      this.ScreenpopOnClickToDialListView = <boolean>config['variables']['ScreenpopOnClickToDialListView'];

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['EnableCallActivity'] !== undefined) {
        this.enableCallActivity = <boolean>config['CallActivity']['variables']['EnableCallActivity'];
      }

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['EnableAutoSave'] !== undefined) {
        this.enableAutoSave = <boolean>config['CallActivity']['variables']['EnableAutoSave'];
      }

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['EnableDiscard'] !== undefined) {
        this.enableDiscard = <boolean>config['CallActivity']['variables']['EnableDiscard'];
      }

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['AutoSaveTimer'] !== undefined) {
        this.autoSaveTimer = <number>config['CallActivity']['variables']['AutoSaveTimer'];
      }
      this.storageService.maxRecentItems = <number>(config['CallActivity'] ? config['CallActivity']['variables']['MaxRecentItems'] : config['variables']['MaxRecentItems']);

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['NameObjects']) {
        this.storageService.setNameObjects(config['CallActivity']['variables']['NameObjects']);
      }

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['ActivityExpirationTimeInSeconds'] != null) {
        this.activityExpirationTime = config['CallActivity']['variables']['ActivityExpirationTime'] as number;
      }

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['ActivityVerificationFrequency'] != null) {
        this.activityVerificationFrequency = config['CallActivity']['variables']['ActivityVerificationFrequency'] as number;
      }
      if (config['CallActivity'] && config['CallActivity']['variables']) {
        this.storageService.isTabbedUI = <boolean>config['CallActivity']['variables']['TabbedActivityUI'];
      }
      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['DelayActivitySave'] != null) {
        this.delayActivitySave = config['CallActivity']['variables']['DelayActivitySave'] as number;
      }

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['DelayActivitySavePerChannel'] != null) {
        const channelDelays = config['CallActivity']['variables']['DelayActivitySavePerChannel'];

        for (const channel of Object.keys(channelDelays)) {
          if (CHANNEL_TYPES[channel] || CHANNEL_TYPES[channel] === 0) {
            this.delayActivitySavePerChannel[CHANNEL_TYPES[channel]] = parseInt(channelDelays[channel], 10) ?? this.delayActivitySave;
          }
        }
      }

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['SwitchToLastActiveScenario'] != null) {
        this.switchToLastActiveScenario = config['CallActivity']['variables']['SwitchToLastActiveScenario'] as boolean;
      }

      if (config['CallActivity'] && config['CallActivity']['variables'] && config['CallActivity']['variables']['APIToProperName']) {
        // This is the mapping of the object's API name (with the __c etc.) to its proper human readable name
        this.apiToProperName = config['CallActivity']['variables']['APIToProperName'];
      }

      // Custom association variables
      if (config['ClickToDialActivityAssociation'] && config['ClickToDialActivityAssociation']['variables'] && config['ClickToDialActivityAssociation']['variables']['EnableCustomAssociation']) {
        this.enableCustomAssociation = true;
        const associationVariables = config['ClickToDialActivityAssociation']['variables'];

        // This is the mapping of the object's API name (with the __c etc.) to the related entity's API field name (with the __c etc.)
        if (associationVariables['RelatedFieldNames']) {
          this.relatedFieldNames = associationVariables['RelatedFieldNames'];
        }

        // This is the mapping of 3 digit prefix to object name (API name with the __c)
        if (associationVariables['ObjectPrefixMap']) {
          this.objectPrefixMap = Object.assign(this.objectPrefixMap, associationVariables['ObjectPrefixMap']);
        }
      }

      if (config['variables'] && config.variables['Floating']) {
        this.isFloating = config.variables.Floating as boolean;
      }

      this.loggerService.logger.logDebug('Salesforce - Home : END : Reading Configuration from Salesforce App');
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Reading Configuration. Config Info : ' + JSON.stringify(config) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected formatPhoneNumber(inputNumber: string, phoneNumberFormat: Object): string {
    try {
      this.loggerService.logger.logTrace('Salesforce - Home : START : Formatting Phone Number. Input Number : ' + inputNumber + '. Configured Format : ' + JSON.stringify(phoneNumberFormat));
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if ((inputFormat.match(/x/g) || []).length !== (outputFormat.match(/x/g) || []).length) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (inputFormat[j] !== '?' && inputNumber[j] !== inputFormat[j]) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber = outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.loggerService.logger.logTrace('Salesforce - Home : END : Formatting Phone Number. Input Number : ' + inputNumber + '. Configured Format : ' + JSON.stringify(phoneNumberFormat) + '. Output Number : ' + outputNumber);
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.loggerService.logger.logError(
        'Salesforce - Home : ERROR : Formatting Phone Number. Input Number : ' + inputNumber + '. Configured Format : ' + JSON.stringify(phoneNumberFormat) + '. Error Information : ' + JSON.stringify(error)
      );
    }
    this.loggerService.logger.logTrace('Salesforce - Home : END : Formatting Phone Number. Input Number : ' + inputNumber + '. Configured Format : ' + JSON.stringify(phoneNumberFormat) + '. Output Number : ' + inputNumber);
    return inputNumber;
  }

  protected clickToDialFormatPhoneNumber(number: any) {
    const configuredInputFormats = Object.keys(this.clickToDialPhoneReformatMap);
    for (let i = 0; i < configuredInputFormats.length; i++) {
      let formatCheck = true;
      if (number.length === configuredInputFormats[i].length) {
        // Length of incoming number matches length of a configured input format
        // Now Validate # of X's in input/output
        const inputFormat = configuredInputFormats[i];
        const outputFormat = this.clickToDialPhoneReformatMap[configuredInputFormats[i]];
        const arrInputDigits = [];
        let outputNumber = '';
        let outputIncrement = 0;
        let plainNumber = '';

        if ((inputFormat.match(/x/g) || []).length !== (outputFormat.match(/x/g) || []).length) {
          continue;
        }
        if ((inputFormat.match(/-/g) || []).length !== (number.match(/-/g) || []).length) {
          continue;
        }

        for (let j = 0; j < inputFormat.length; j++) {
          if (inputFormat[j] === 'x') {
            arrInputDigits.push(j);
          } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
            formatCheck = false;
            break;
          }
        }
        if (formatCheck) {
          for (let k = 0; k < outputFormat.length; k++) {
            if (outputFormat[k] === 'x') {
              outputNumber = outputNumber + number[arrInputDigits[outputIncrement]];
              outputIncrement++;
            } else {
              outputNumber = outputNumber + outputFormat[k];
            }
          }

          // Get the plain number xxxxxxxxxx
          for (let j = 0; j < arrInputDigits.length; j++) {
            plainNumber += number[arrInputDigits[j]];
          }

          return {
            plainNumber: plainNumber,
            outputNumber: outputNumber
          };
        }
      }
    }

    return {
      plainNumber: number,
      outputNumber: number
    };
  }

  @bind
  private cleanupScenarios(): void {
    try {
      this.loggerService.logger.logLoop('SalesForce - Home : START : Cleaning Expired Scenarios');

      for (const activityId of Object.keys(this.storageService.activityList)) {
        const activity = this.storageService.activityList[activityId];

        // Check if activity is not in recent activities
        if (!activity || !this.storageService.recentActivityListContains(activity.ScenarioId)) {
          // Check if LastUpdated is older than threshold
          const date = new Date();
          if (date.getTime() - new Date(activity.LastUpdated).getTime() > this.activityExpirationTime) {
            this.deleteOrphanedActivity(activity.ScenarioId);
          }
        }
      }

      this.loggerService.logger.logLoop('SalesForce - Home : END : Cleaning Expired Scenarios');
    } catch (error) {
      this.loggerService.logger.logError('SalesForce - Home : ERROR : Cleaning Expired Scenarios. Error Information : ' + JSON.stringify(error));
    }
  }

  protected deleteOrphanedActivity(scenarioId: string): void {
    try {
      this.loggerService.logger.logInformation('SalesForce - Home : START : Checking for Orphaned Activity With Scenario ID : ' + scenarioId);
      if (!this.scenarioInteractionMappings[scenarioId]) {
        this.loggerService.logger.logInformation('Salesforce - Home : deleteOrphanedActivity : Removing Orphaned Activity with Scenario ID ' + scenarioId);
        this.saveAndStoreActivity(scenarioId);
      }
      this.loggerService.logger.logInformation('SalesForce - Home : END : Checked for Orphaned Activity With Scenario ID : ' + scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('SalesForce - Home : ERROR :  Orphaned Activity With Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected saveAndStoreActivity(scenarioId: string) {
    this.saveActivity(scenarioId, true, this.enableAutoSave);
    this.storageService.onInteractionDisconnect(scenarioId, this.enableAutoSave);
    this.loggerService.logger.logDebug('SalesForce - Home : saveAndStoreActivity : Removing scenario ' + scenarioId + ' from scenario interaction map ' + JSON.stringify(this.scenarioInteractionMappings));
    delete this.scenarioInteractionMappings[scenarioId];
  }

  public checkIfRecentActivitiesExist() {
    try {
      return this.storageService.recentScenarioIdList.length > 0 ? true : false;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Checking if Recent Activities Exist. Error Information : ' + JSON.stringify(error));
    }
  }

  @bind
  protected removeLocalStorageOnLogout(): Promise<any> {
    this.enableClickToDial(false);
    localStorage.clear();
    return new Promise((resolve, reject) => {});
  }

  protected async sendActivityLMS(activity: IActivity) {
    try {
      if (this.appConfig['variables']['LMSChannelName']) {
        const tempActivity = {
          messageType: 'Activity',
          WhoObject: activity.WhoObject,
          WhatObject: activity.WhatObject,
          CallType: activity.CallType,
          ChannelType: activity.ChannelType,
          CallDurationInSeconds: activity.CallDurationInSeconds,
          Subject: activity.Subject,
          Description: activity.Description,
          Status: activity.Status,
          ActivityDate: activity.ActivityDate,
          ActivityId: activity.ActivityId,
          ScenarioId: activity.ScenarioId,
          TaskSubtype: activity.TaskSubtype,
          contactSource: activity.contactSource,
          CadFields: activity.CadFields,
          IsActive: activity.IsActive
        };

        const message = {
          sourceSystem: 'DaVinci',
          messageToSend: tempActivity,
          timePublished: new Date().toLocaleTimeString()
        };

        const event = {
          channelName: this.appConfig['variables']['LMSChannelName'],
          message: message
        };

        const response = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.SEND_LMS, event);
        this.loggerService.logger.logDebug('Salesforce - Home : Received send Activity LMS response from bridge. Scenario ID : ' + activity.ScenarioId + ' ' + JSON.stringify(response));
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : send Activity LMS . More Info : ' + JSON.stringify(error));
    }
  }

  protected async sendInteractionLMS(interaction: IInteraction) {
    try {
      if (this.appConfig['variables']['LMSChannelName']) {
        const tempInteraction = {
          messageType: 'Interaction',
          channelType: CHANNEL_TYPES[interaction.channelType],
          state: INTERACTION_STATES[interaction.state],
          details: interaction.details,
          interactionId: interaction.interactionId,
          scenarioId: interaction.scenarioId,
          direction: interaction.direction === INTERACTION_DIRECTION_TYPES.Inbound ? 'Inbound' : interaction.direction === INTERACTION_DIRECTION_TYPES.Outbound ? 'Outbound' : 'Internal',
          userFocus: interaction.userFocus
        };

        const message = {
          sourceSystem: 'DaVinci',
          messageToSend: tempInteraction,
          timePublished: new Date().toLocaleTimeString()
        };

        const event = {
          channelName: this.appConfig['variables']['LMSChannelName'],
          message: message
        };

        const response = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.SEND_LMS, event);
        this.loggerService.logger.logDebug('Salesforce - Home : Received send Interaction LMS response from bridge. Scenario ID : ' + interaction.scenarioId + ' ' + JSON.stringify(response));
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : send Interaction LMS . More Info : ' + JSON.stringify(error));
    }
  }

  protected async setSoftphonePanelVisibility(visible) {
    try {
      const event = {
        visible: visible,
        isFloating: this.isFloating
      };

      const response = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.SET_SOFTPHONE_VISIBLE, event);
      this.loggerService.logger.logDebug('Salesforce - Home : Received setSoftphonePanelVisibility response from bridge. ' + JSON.stringify(response));
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : setSoftphonePanelVisibility . More Info : ' + JSON.stringify(error));
    }
  }

  addCadData(modifiedNumber: ModifiedNumber, cadData) {
    try {
      if (modifiedNumber.prefix) {
        cadData.fields['TargetedDial.Prefix.Key'] = {
          DevName: '',
          DisplayName: '',
          Value: modifiedNumber.prefix.key
        };
        cadData.fields['TargetedDial.Prefix.Value'] = {
          DevName: '',
          DisplayName: '',
          Value: modifiedNumber.prefix.val
        };
      }
      if (modifiedNumber.suffix) {
        cadData.fields['TargetedDial.Suffix.Key'] = {
          DevName: '',
          DisplayName: '',
          Value: modifiedNumber.suffix.key
        };
        cadData.fields['TargetedDial.Suffix.Value'] = {
          DevName: '',
          DisplayName: '',
          Value: modifiedNumber.suffix.val
        };
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : addCadData . More Info : ' + JSON.stringify(error));
    }
  }

  // eslint-disable-next-line max-statements
  protected async onInteraction(interaction: IInteraction): Promise<SearchRecords> {
    this.log(LOG_LEVEL.Information, 'onInteraction', 'Received interaction', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId });
    this.log(LOG_LEVEL.Trace, 'onInteraction', 'Received interaction', interaction);
    try {
      const cadData = interaction.details;

      // Check for HVS work item
      this.logger.logInformation(`HVS INFO:\n\
        State: ${INTERACTION_STATES[interaction.state]}\n
        Interaction ID: ${interaction.interactionId}\n
        Scenario ID: ${interaction.scenarioId}\n
        Direction: ${INTERACTION_DIRECTION_TYPES[interaction.direction]}\n
        Work Item: ${JSON.stringify(this.curHvsWorkItem, null, 2)}`);

      if (interaction.direction === INTERACTION_DIRECTION_TYPES.Outbound && this.curHvsWorkItem) {
        // Match work item w/ scenario if this work item has not yet been matched
        if (!this.curHvsWorkItem.scenarioId) {
          this.curHvsWorkItem.scenarioId = interaction.scenarioId;
        }
        // If call was connected, record on work item
        if (this.curHvsWorkItem.scenarioId === interaction.scenarioId && interaction.state === INTERACTION_STATES.Connected) {
          this.curHvsWorkItem.wasConnected = true;
        }
      }

      switch (interaction.channelType) {
        case CHANNEL_TYPES.Telephony:
          if (cadData.fields['Phone']) {
            let matchedNumber = '';
            const phoneNum = this.formatPhoneNumber(cadData.fields['Phone'].Value, this.phoneNumberFormat);
            const ctdReformattedPhoneNum = this.clickToDialFormatPhoneNumber(phoneNum).outputNumber;

            if (this.phoneNumberToModifiedNumber.has(phoneNum)) {
              matchedNumber = phoneNum;
            } else if (this.phoneNumberToModifiedNumber.has(cadData.fields['Phone'].Value)) {
              matchedNumber = cadData.fields['Phone'].Value;
            } else if (this.phoneNumberToModifiedNumber.has(ctdReformattedPhoneNum)) {
              matchedNumber = ctdReformattedPhoneNum;
            }

            if (matchedNumber) {
              const modifiedNumber = this.phoneNumberToModifiedNumber.get(matchedNumber);
              if (modifiedNumber.isNew) {
                modifiedNumber.isNew = false;
                modifiedNumber.scenarioID = interaction.scenarioId;
                this.addCadData(modifiedNumber, cadData);
              } else if (modifiedNumber.scenarioID === interaction.scenarioId) {
                this.addCadData(modifiedNumber, cadData);
              }
            }
          }
          break;
        default:
          break;
      }

      const scenarioId = interaction.scenarioId;
      if (interaction.state !== INTERACTION_STATES.Disconnected && this.storageService.recentActivityListContains(scenarioId)) {
        this.storageService.removeRecentActivity(scenarioId);
      }
      let isNewScenarioId = false;
      let clickToDialEntity = '';
      let ctdWhoWhatEntity = {};
      let lastOnFocusWasAnEntity = false;
      this.storageService.updateCadFields(interaction, this.cadActivityMap);

      if (interaction.state === INTERACTION_STATES.Alerting || interaction.state === INTERACTION_STATES.Connected) {
        const formattedSubject = this.buildSubjectText(interaction);

        if (
          this.storageService.activityList[interaction.scenarioId] != null &&
          this.storageService.activityList[interaction.scenarioId] !== undefined &&
          !this.storageService.activityList[interaction.scenarioId].IsSubjectChanged &&
          this.storageService.activityList[interaction.scenarioId].InitialInteractionId === interaction.interactionId
        ) {
          this.storageService.setSubject(formattedSubject, interaction.scenarioId);
          this.storageService.compareActivityFields(interaction.scenarioId);
        }
      }

      if (this.storageService.recentActivityListContains(scenarioId) && this.storageService.currentScenarioId !== scenarioId) {
        this.saveActivity(scenarioId, true, this.enableAutoSave);
        return;
      }

      if (interaction.details && interaction.details.fields && interaction.details.fields.Phone && interaction.details.fields.Phone.Value) {
        const phoneNum = this.formatPhoneNumber(interaction.details.fields.Phone.Value, this.phoneNumberFormat);

        const ctdReformattedPhoneNum = this.clickToDialFormatPhoneNumber(interaction.details.fields.Phone.Value).outputNumber;

        if (this.clickToDialList[phoneNum]) {
          clickToDialEntity = this.clickToDialList[phoneNum].entity;
          ctdWhoWhatEntity = this.ctdWhoWhatList[phoneNum].entity;
          delete this.clickToDialList[phoneNum];
          delete this.ctdWhoWhatList[phoneNum];
        }

        // Check to see if there is a match with the RAW phone number.
        if (!clickToDialEntity) {
          if (this.clickToDialList[interaction.details.fields.Phone.Value]) {
            clickToDialEntity = this.clickToDialList[interaction.details.fields.Phone.Value].entity;
            ctdWhoWhatEntity = this.ctdWhoWhatList[interaction.details.fields.Phone.Value].entity;
            delete this.clickToDialList[interaction.details.fields.Phone.Value];
            delete this.ctdWhoWhatList[interaction.details.fields.Phone.Value];
          }
        }

        // Check to see if there is a match formatting with the CTD map.
        if (!clickToDialEntity) {
          if (this.clickToDialList[ctdReformattedPhoneNum]) {
            clickToDialEntity = this.clickToDialList[ctdReformattedPhoneNum].entity;
            ctdWhoWhatEntity = this.ctdWhoWhatList[ctdReformattedPhoneNum].entity;
            delete this.clickToDialList[ctdReformattedPhoneNum];
            delete this.ctdWhoWhatList[ctdReformattedPhoneNum];
          }
        }

        let numberToCheckLastFocus = phoneNum;

        // If not found, check for alternate numbers
        if (!clickToDialEntity) {
          let outputNumber = '';
          let keys = Object.keys(this.clickToDialList);
          let keyFound = false;

          for (let i = 0; i < keys.length; i++) {
            if (this.clickToDialList[keys[i]].alternateNumbers) {
              for (const number of this.clickToDialList[keys[i]].alternateNumbers) {
                if (number === phoneNum) {
                  clickToDialEntity = this.clickToDialList[keys[i]].entity;
                  keyFound = true;
                  delete this.clickToDialList[keys[i]];
                  break;
                }
              }
            }

            if (keyFound) {
              break;
            }
          }

          if (keyFound) {
            keys = Object.keys(this.ctdWhoWhatList);
            keyFound = false;

            for (let i = 0; i < keys.length; i++) {
              if (this.ctdWhoWhatList[keys[i]].alternateNumbers) {
                for (const number of this.ctdWhoWhatList[keys[i]].alternateNumbers) {
                  if (number === phoneNum) {
                    ctdWhoWhatEntity = this.ctdWhoWhatList[keys[i]].entity;
                    keyFound = true;
                    outputNumber = keys[i];
                    delete this.ctdWhoWhatList[keys[i]];
                    break;
                  }
                }
              }

              if (keyFound) {
                numberToCheckLastFocus = outputNumber;
                break;
              }
            }
          }
        }

        if (this.lastOnFocusWasAnEntityList.indexOf(numberToCheckLastFocus) > -1) {
          lastOnFocusWasAnEntity = true;
          const index = this.lastOnFocusWasAnEntityList.indexOf(numberToCheckLastFocus, 0);
          if (index > -1) {
            this.lastOnFocusWasAnEntityList.splice(index, 1);
          }
        }
        interaction.details.fields.Phone.Value = phoneNum;
      }

      isNewScenarioId = this.processIfNewScenario(interaction);

      if (isNewScenarioId) {
        this.setSoftphonePanelVisibility(true);
      }

      // If we set the current scenario Id for a new scenario, the activity window will appear,
      // but the activity object will not exist if the interaction is Alerting and screenPopOnAlert
      // is false. We can check for this by seeing if the interaction was added to the
      // scenarioInteractionMappings object
      if (this.scenarioInteractionMappings[interaction.scenarioId][interaction.interactionId]) {
        if (interaction['userFocus'] || (this.storageService.activeScenarioIdList.length >= 1 && this.storageService.activeScenarioIdList.indexOf(scenarioId) >= 0)) {
          this.storageService.setCurrentScenarioId(scenarioId, interaction.direction);
          this.clickSubject.next(interaction.scenarioId);
        }
      }

      // Verify if Hold operations to calculate the Hold Time
      this.storageService.updateInteractionDurationActivity(interaction.scenarioId, interaction.interactionId, interaction.state === INTERACTION_STATES.Disconnected);
      this.storageService.updateHoldInteractionActivityField(interaction.scenarioId, interaction.interactionId, interaction.state === INTERACTION_STATES.OnHold);

      if (interaction.state === INTERACTION_STATES.Disconnected) {
        // If interaction is Disconnected, remove interaction immediately so it doesn't
        // hang
        this.log(LOG_LEVEL.Debug, 'onInteraction', 'Removing scenario from scenario interaction map', { scenarioId: interaction.scenarioId, scenarioInteractionMappings: this.scenarioInteractionMappings });
        delete this.scenarioInteractionMappings[interaction.scenarioId][interaction.interactionId];

        // First process if channel specific delay is defined and is 0
        // Then process if it is defined and is not 0
        // Then conclude that channel specific delay is not defined
        // Check if generic delay is 0 or is not defined, and process accordingly
        // Lastly, generic delay is not 0, process accordingly

        if (this.delayActivitySavePerChannel[interaction.channelType] === 0) {
          this.log(LOG_LEVEL.Debug, 'onInteraction', 'Deleting with delayActivitySavePerChannel = 0', CHANNEL_TYPES[interaction.channelType]);
          this.processSaveOnDisconnect(interaction);
        } else if (this.delayActivitySavePerChannel[interaction.channelType]) {
          // Delay is defined, and is something other than 0
          this.log(LOG_LEVEL.Debug, 'onInteraction', 'Deleting with delayActivitySavePerChannel', { delay: this.delayActivitySavePerChannel[interaction.channelType], channelType: CHANNEL_TYPES[interaction.channelType] });

          setTimeout(this.processSaveOnDisconnect.bind(this), this.delayActivitySavePerChannel[interaction.channelType], interaction);
        } else {
          // Delay is undefined, either missing in config, or missing in CHANNEL_TYPES
          // Fall back to delayActivitySave
          if (!this.delayActivitySave) {
            // delayActivitySave is either not defined, or is 0
            this.log(LOG_LEVEL.Debug, 'onInteraction', 'Deleting with delayActivitySave undefined and generic delay being 0 or undefined for channel type', CHANNEL_TYPES[interaction.channelType]);

            this.processSaveOnDisconnect(interaction);
          } else {
            // delayActivitySave is defined, and is something other than 0
            this.log(LOG_LEVEL.Debug, 'onInteraction', 'Deleting with delayActivitySave', { delay: this.delayActivitySave, channelType: CHANNEL_TYPES[interaction.channelType] });

            setTimeout(this.processSaveOnDisconnect.bind(this), this.delayActivitySave, interaction);
          }
        }
      } else if (!(interaction.state === INTERACTION_STATES.Alerting && this.screenpopOnAlert === false)) {
        if (clickToDialEntity) {
          if (this.ScreenpopOnClickToDialListView && !lastOnFocusWasAnEntity) {
            interaction.details.type = 'ClickToDialScreenpop';
          } else {
            interaction.details.type = 'ClickToDialNoScreenpop';
          }
          interaction.details.id = clickToDialEntity;
          this.updateClickToDialWhoWhatLists(ctdWhoWhatEntity, scenarioId);
        }
        if (!this.storageService.searchRecordList[scenarioId]) {
          const searchRecord = await this.searchAndScreenpop(interaction, isNewScenarioId);
          this.storageService.setsearchRecordList(searchRecord.toJSON(), scenarioId);
          this.loggerService.logger.logDebug('Salesforce - Home : END : Interaction recieved: ' + JSON.stringify(interaction));
          return searchRecord;
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : On Interaction. More Info : ' + JSON.stringify(error));
    } finally {
      this.sendInteractionLMS(interaction);
    }
    this.loggerService.logger.logDebug('Salesforce - Home : END : Interaction recieved: ' + JSON.stringify(interaction));
    return;
  }

  private processSaveOnDisconnect(interaction: IInteraction) {
    const functionName = 'processSaveOnDisconnect';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Processing save on Disconnect.', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId });
      // If total interactions in Scenario is 0 once the timer expires, delete the scenario
      if (this.scenarioInteractionMappings[interaction.scenarioId] && Object.keys(this.scenarioInteractionMappings[interaction.scenarioId]).length === 0) {
        this.log(LOG_LEVEL.Debug, functionName, 'Deleting scenario.', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId });
        this.deleteExistingScenario(interaction);
        let activeScenariosCount = this.storageService.activeScenarioIdList.length;

        // If the interaction is disconnected, and there are still active scenarios, update current scenario to most recent active scenario. If SwitchToLastActiveScenario is false, unset current scenario.
        if (activeScenariosCount > 0 && this.switchToLastActiveScenario) {
          // Iterate through valid (non-orphaned) scenarios. This will avoid setting the current scenario id to an activity that
          // for any reason was left active but can no longer be controlled, thus avoiding the hanging activity display.
          let newScenarioId = this.storageService.activeScenarioIdList[activeScenariosCount - 1];
          this.log(LOG_LEVEL.Debug, functionName, 'Switching to last active scenario.', { activeScenariosCount, newScenarioId });
          while (activeScenariosCount > 0 && !this.scenarioInteractionMappings[newScenarioId]) {
            activeScenariosCount--;
            newScenarioId = this.storageService.activeScenarioIdList[activeScenariosCount - 1];
          }
          this.storageService.currentScenarioId = this.scenarioInteractionMappings[newScenarioId] ? newScenarioId : null;
        } else {
          this.storageService.currentScenarioId = null;
        }
      }

      this.log(LOG_LEVEL.Debug, functionName, 'Processing save on Disconnect completed.', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId });
      this.log(LOG_LEVEL.Trace, functionName, 'Processing save on Disconnect completed.', interaction);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error processing save on Disconnect.', error);
    }
  }

  private async updateClickToDialWhoWhatLists(entity: Object, scenarioId: string): Promise<void> {
    try {
      this.loggerService.logger.logTrace('Salesforce - Home : START : Update Click To Dial Who/What Lists. Scenario ID : ' + scenarioId + '. Entity Info : ' + JSON.stringify(entity));
      if (entity) {
        const entityForSetActivityDetails: IActivityDetails = {
          displayName: entity['IsPersonAccount'] === true ? 'Person Account' : entity['RecordType'],
          objectId: entity['Id'],
          objectName: entity['Name'],
          objectType: entity['RecordType'],
          url: ''
        };

        let addClickedEntityToList = true;

        // Convert display name to proper name
        if (this.apiToProperName && entityForSetActivityDetails.displayName in this.apiToProperName) {
          entityForSetActivityDetails.displayName = this.apiToProperName[entityForSetActivityDetails.objectType];
        }

        if (this.enableCustomAssociation) {
          const associationResult = await this.performCustomObjectAssociation(entity, scenarioId);

          let isClickedObjectWho = false;

          if (this.storageService.nameObjects.includes(entityForSetActivityDetails.objectType)) {
            isClickedObjectWho = true;
          }

          if ((isClickedObjectWho && associationResult === CUSTOM_OBJECT_ASSOCIATION_RESULT.AssociatedWhoObject) || (!isClickedObjectWho && associationResult === CUSTOM_OBJECT_ASSOCIATION_RESULT.AssociatedWhatObject)) {
            addClickedEntityToList = false;
          }
        }

        if (addClickedEntityToList) {
          this.storageService.updateWhoWhatLists(entityForSetActivityDetails, scenarioId);
        }
      }
      this.loggerService.logger.logTrace('Salesforce - Home : END : Update Click To Dial Who/What Lists. Scenario ID : ' + scenarioId + '. Entity Info : ' + JSON.stringify(entity));
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Updating Click to Dial Who/What Lists. Scenario ID : ' + scenarioId + '. Entity Info : ' + JSON.stringify(entity) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  // eslint-disable-next-line max-statements
  private async performCustomObjectAssociation(entity, scenarioId): Promise<CUSTOM_OBJECT_ASSOCIATION_RESULT> {
    try {
      this.loggerService.logger.logTrace('Salesforce - Home : START : performCustomObjectAssociation. Scenario ID : ' + scenarioId + '. Entity Info : ' + JSON.stringify(entity));
      // If the related field name, doesn't exist, do nothing
      if (!this.relatedFieldNames) {
        return;
      }

      // Get the link field for this type of object
      const relatedFieldName = this.relatedFieldNames[entity.RecordType];

      // If the related entity field for this object type is unknown, do nothing
      if (!relatedFieldName) {
        this.loggerService.logger.logWarning(
          `Salesforce - Home : performCustomObjectAssociation.
          Scenario ID: ${scenarioId}. Related field could not be determined.
          Please add related field in RelatedFieldNames with key: ${entity.RecordType}`
        );

        return CUSTOM_OBJECT_ASSOCIATION_RESULT.CouldNotAssociateObject;
      }

      this.loggerService.logger.logTrace(
        `Salesforce - Home : performCustomObjectAssociation.
        Scenario ID: ${scenarioId}. Identified related field as ${relatedFieldName}`
      );

      // Create query to get related field from clicked object
      let searchEntity = {
        entity: entity.RecordType,
        field: 'Id',
        value: entity.Id,
        searchFields: ',' + relatedFieldName
      };

      // Get the clicked object's related field
      const clickedObject = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.CUSTOM_SEARCH, searchEntity);

      // Extract the related field
      const relatedObjectId = clickedObject[0][relatedFieldName];

      // If the related field is empty, do nothing
      if (!relatedObjectId) {
        this.loggerService.logger.logWarning(
          // eslint-disable-next-line max-len
          `Salesforce - Home : performCustomObjectAssociation.
          Scenario ID: ${scenarioId}. Related field ${relatedFieldName} is likely empty or invalid`
        );

        return CUSTOM_OBJECT_ASSOCIATION_RESULT.CouldNotAssociateObject;
      }

      // Determine the type of the related object
      const relatedObjectType = this.objectPrefixMap[relatedObjectId.substring(0, 3)];

      // If the related object type is unknown, do nothing
      if (!relatedObjectType) {
        this.loggerService.logger.logWarning(
          `Salesforce - Home : performCustomObjectAssociation. Scenario ID: ${scenarioId}. Unknown object type with prefix
          ${relatedObjectId.substring(0, 3)}
          encountered. Please add the object tothe ObjectMapPrefix configuration.`
        );

        return CUSTOM_OBJECT_ASSOCIATION_RESULT.CouldNotAssociateObject;
      }

      // Create query to get the related object
      searchEntity = {
        entity: relatedObjectType,
        field: 'Id',
        value: relatedObjectId,
        searchFields: ''
      };

      // Get the related object
      const relatedObject = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.CUSTOM_SEARCH, searchEntity);

      // Construct the whoWhat object
      const whoWhatEntity: IActivityDetails = {
        displayName: relatedObjectType,
        objectId: relatedObjectId,
        objectName: relatedObject[0].Name,
        objectType: relatedObjectType,
        url: ''
      };

      // if (
      //   this.apiToProperName &&
      //   whoWhatEntity.objectType in this.apiToProperName
      // ) {
      //   whoWhatEntity.objectType = this.apiToProperName[whoWhatEntity.objectType];
      // }

      // If the related object is an account, determine if it is a person account
      if (relatedObjectType === 'Account') {
        // Determine whether the related account is a person account or not
        const isPersonAccount = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.IS_PERSON_ACCOUNT, searchEntity);

        // It is a person account, so now we need to get the cooresponding contact
        if (isPersonAccount) {
          // Create query to get the contactId of the person account
          searchEntity = {
            entity: relatedObjectType,
            field: 'Id',
            value: relatedObjectId,
            searchFields: ',PersonContactId'
          };

          // Get the person account's related contact ID
          const relatedContact = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.CUSTOM_SEARCH, searchEntity);

          // Update who what object to reflect as a person account (contact)
          whoWhatEntity.objectType = 'Contact';
          whoWhatEntity.displayName = 'Person Account';
          whoWhatEntity.objectId = relatedContact[0].PersonContactId;
        }
      }

      let isRelatedObjectWho = false;
      const relatedEntityType = whoWhatEntity.objectType;

      // if (this.apiToProperName && relatedEntityType in this.apiToProperName) {
      //   relatedEntityType = this.apiToProperName[relatedEntityType];
      // }

      if (this.storageService.nameObjects.includes(relatedEntityType)) {
        isRelatedObjectWho = true;
      }

      // Convert display name to proper name
      if (this.apiToProperName && whoWhatEntity.displayName in this.apiToProperName) {
        whoWhatEntity.displayName = this.apiToProperName[whoWhatEntity.objectType];
      }

      // Update the who what list
      this.storageService.updateWhoWhatLists(whoWhatEntity, scenarioId);

      this.loggerService.logger.logTrace(
        'Salesforce - Home : END : performCustomObjectAssociation. Added related object as ' + isRelatedObjectWho ? 'Who Object' : 'What Object' + '. Scenario ID : ' + scenarioId + '. Related Entity Info : ' + JSON.stringify(whoWhatEntity)
      );

      if (isRelatedObjectWho) {
        return CUSTOM_OBJECT_ASSOCIATION_RESULT.AssociatedWhoObject;
      } else {
        return CUSTOM_OBJECT_ASSOCIATION_RESULT.AssociatedWhatObject;
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : performCustomObjectAssociation. Scenario ID : ' + scenarioId + '. Entity Info : ' + JSON.stringify(entity) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private async searchAndScreenpop(interaction: IInteraction, isNewScenarioId: boolean) {
    try {
      if (this.shouldPreformScreenpop(interaction, isNewScenarioId)) {
        this.loggerService.logger.logInformation('Salesforce - Home : Screen pop on interaction. Scenario ID : ' + interaction.scenarioId);
        this.loggerService.logger.logDebug('Salesforce - Home : Screen pop on interaction. Interaction Info : ' + JSON.stringify(interaction));
        const records = await this.preformScreenpop(interaction);
        this.loggerService.logger.logDebug('Salesforce - Home : Screen pop on interaction. Results : ' + JSON.stringify(records));
        return records;
      } else {
        this.loggerService.logger.logInformation('Salesforce - Home : Search on interaction. Scenario ID : ' + interaction.scenarioId);
        this.loggerService.logger.logDebug('Salesforce - Home : Search on interaction. Interaction Info : ' + JSON.stringify(interaction));
        const event = this.generateEventForScreenpop(interaction);
        event['search'] = true;
        const screenpopResult = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.SEARCH, event);
        const records = this.formatCrmResults(screenpopResult);
        this.loggerService.logger.logDebug('Salesforce - Home : Search on interaction. Results after formatting : ' + JSON.stringify(records));
        return records;
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Search and Screen pop. Interaction Info : ' + JSON.stringify(interaction) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected deleteExistingScenario(interaction: IInteraction): void {
    const fname = 'Salesforce - Home : deleteExistingScenario():';
    try {
      this.loggerService.logger.logInformation('Salesforce - Home : START : Removing Scenario ID : ' + interaction.scenarioId);
      if (this.scenarioInteractionMappings[interaction.scenarioId]) {
        this.loggerService.logger.logDebug('SalesForce - Home : deleteExistingScenario : Removing scenario ' + interaction.scenarioId + ' from scenario interaction map ' + JSON.stringify(this.scenarioInteractionMappings));
        delete this.scenarioInteractionMappings[interaction.scenarioId][interaction.interactionId];
        if (Object.keys(this.scenarioInteractionMappings[interaction.scenarioId]).length === 0) {
          this.saveActivity(interaction.scenarioId, true, this.enableAutoSave).then((activityId: string) => {
            this.logger.logInformation(`${fname} Attempting to close HVS work item for scenario ${interaction.scenarioId}`);

            if (this.curHvsWorkItem && this.curHvsWorkItem.scenarioId === interaction.scenarioId) {
              const workEndData: IHvsCompleteWorkItemPayload = {
                workId: this.curHvsWorkItem.newWorkItem.workId,
                attributes: {
                  disposition: this.storageService.getActivity(interaction.scenarioId).Status,
                  taskId: activityId || this.storageService.getActivity(interaction.scenarioId).ActivityId,
                  wasConnected: this.curHvsWorkItem.wasConnected
                }
              };

              this.logger.logInformation(`${fname} Work Item data found. Ending HVS work item: ${JSON.stringify(workEndData)}`);

              this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.HVS_WORK_END, workEndData);
              this.curHvsWorkItem = null;
            } else {
              this.logger.logInformation(`${fname} No HVS work item found for scenario ${interaction.scenarioId}`);
            }
          });
          this.storageService.onInteractionDisconnect(interaction.scenarioId, this.enableAutoSave);
          this.loggerService.logger.logDebug('SalesForce - Home : deleteExistingScenario : Removing scenario ' + interaction.scenarioId + ' from scenario interaction map ' + JSON.stringify(this.scenarioInteractionMappings));
          delete this.scenarioInteractionMappings[interaction.scenarioId];
        }
      }
      this.loggerService.logger.logInformation('Salesforce - Home : END : Removing Scenario ID : ' + interaction.scenarioId);
    } catch (error) {
      this.loggerService.logger.logError(
        'Salesforce - Home : ERROR : Deleting existing Scenario. Scenario ID : ' + interaction.scenarioId + '. Interaction Info : ' + JSON.stringify(interaction) + '. Error Information : ' + JSON.stringify(error)
      );
    }
  }

  protected processIfNewScenario(interaction: IInteraction): boolean {
    try {
      this.loggerService.logger.logTrace('Salesforce - Home : START : Checking if the interaction is new or existing. Interaction Info : ' + JSON.stringify(interaction));
      // Only add new scenario if the scenario Id is present on the interaction and is "screen poppable".
      // If the state is Alerting, but screenPopOnAlert is false, we cannot add the scenario, because
      // it will not be a new scenario if it changes to Connected.
      if (!this.scenarioInteractionMappings.hasOwnProperty(interaction.scenarioId) && (this.screenpopOnAlert || interaction.state !== INTERACTION_STATES.Alerting)) {
        this.scenarioInteractionMappings[interaction.scenarioId] = {};
        this.scenarioInteractionMappings[interaction.scenarioId][interaction.interactionId] = true;
        if (this.storageService.activeScenarioIdList.indexOf(interaction.scenarioId) < 0) {
          if (this.enableCallActivity) {
            this.storageService.addActivity(this.createActivity(interaction));
            this.saveActivity(interaction.scenarioId, false, this.enableAutoSave);
          }
        }
        this.loggerService.logger.logInformation('Salesforce - Home : New Scenario with Scenario ID : ' + interaction.scenarioId);
        this.loggerService.logger.logTrace('Salesforce - Home : END : Checking if the interaction is new or existing. Interaction Info : ' + JSON.stringify(interaction));
        return true;
      } else if (
        this.scenarioInteractionMappings.hasOwnProperty(interaction.scenarioId) &&
        !this.scenarioInteractionMappings[interaction.scenarioId].hasOwnProperty(interaction.interactionId) &&
        interaction.state !== INTERACTION_STATES.Disconnected
      ) {
        this.loggerService.logger.logTrace('Salesforce - Home : Start : Found an existing scenario with no Interaction. Interaction Info : ' + JSON.stringify(interaction));
        this.scenarioInteractionMappings[interaction.scenarioId][interaction.interactionId] = true;
      }
      this.loggerService.logger.logTrace('Salesforce - Home : END : Checking if the interaction is new or existing. Interaction Info : ' + JSON.stringify(interaction));
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Checking if the interaction is new or existing. Interaction Info : ' + JSON.stringify(interaction) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Home : END : Checking if the interaction is new or existing. Interaction Info : ' + JSON.stringify(interaction));
    return false;
  }

  public buildSubjectText(interaction: IInteraction) {
    this.loggerService.logger.logTrace('Salesforce - Home : START : Building Subject Text. Interaction Info : ' + JSON.stringify(interaction));
    let subjectText = '';
    try {
      const channelType = CHANNEL_TYPES[interaction.channelType];
      if (interaction.details.fields) {
        const fields = interaction.details.fields;
        if (fields.Email) {
          subjectText = `${channelType}[${fields.Email.Value}]`;
        } else if (fields.Phone) {
          if (channelType === 'Telephony') {
            const phoneNum = this.formatPhoneNumber(fields.Phone.Value, this.phoneNumberFormat);
            subjectText = `${'Call'}[${phoneNum}]`;
          } else {
            const phoneN = this.formatPhoneNumber(fields.Phone.Value, this.phoneNumberFormat);
            subjectText = `${channelType}[${phoneN}]`;
          }
        } else if (fields.FullName) {
          subjectText = `${channelType}[${fields.FullName.Value}]`;
        }
      }
      this.loggerService.logger.logInformation('Salesforce - Home : Subject text for Scenario ID : ' + interaction.scenarioId + ' is ' + subjectText);
      this.loggerService.logger.logTrace('Salesforce - Home : END : Building Subject Text. Interaction Info : ' + JSON.stringify(interaction));
    } catch (error) {
      this.loggerService.logger.logError(
        'Salesforce - Home : ERROR : Creating new activity. Scenario ID : ' + interaction.scenarioId + '. Interaction Info : ' + JSON.stringify(interaction) + '. Error Information : ' + JSON.stringify(error)
      );
    }
    return subjectText;
  }

  protected buildTaskSubType(interaction: IInteraction): string {
    this.loggerService.logger.logTrace('Salesforce - Home : START : Building Task Sub Type. Scenario ID : ' + interaction.scenarioId);
    let channelType = '';
    try {
      switch (interaction.channelType) {
        case CHANNEL_TYPES.Telephony: {
          channelType = 'Call';
          break;
        }
        case CHANNEL_TYPES.Email: {
          channelType = 'Email';
          break;
        }
        default: {
          channelType = 'Task';
          break;
        }
      }
      this.loggerService.logger.logInformation('Salesforce - Home : Task Sub Type for Scenario ID : ' + interaction.scenarioId + ' is ' + channelType);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Building Task Sub Type. Scenario ID : ' + interaction.scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Salesforce - Home : END : Building Task Sub Type. Scenario ID : ' + interaction.scenarioId);
    return channelType;
  }

  protected formatCrmResults(crmResults: any): SearchRecords {
    const result = new SearchRecords();
    try {
      this.loggerService.logger.logTrace('Salesforce - Home : START : Formatting CRM Results. CRM Results : ' + JSON.stringify(crmResults));
      const ignoreFields = ['Name', 'displayName', 'object', 'Id', 'RecordType'];
      for (const id of Object.keys(crmResults)) {
        if (crmResults[id]) {
          let recordItem: RecordItem = null;
          if (crmResults[id].Id && crmResults[id].RecordType) {
            recordItem = new RecordItem(crmResults[id].Id, crmResults[id].RecordType, crmResults[id].RecordType);
          } else if (crmResults[id].object && crmResults[id].displayName) {
            recordItem = new RecordItem(id, crmResults[id].object, crmResults[id].displayName);
          }
          if (recordItem !== null) {
            if (crmResults[id].Name) {
              if (recordItem.getMetadata().Type === 'Account') {
                recordItem.setAccountName('Name', 'Name', crmResults[id].Name);
              } else if (recordItem.getMetadata().Type === 'Contact') {
                recordItem.setFullName('Name', 'Name', crmResults[id].Name);
              } else {
                recordItem.setField('Name', 'Name', 'Name', crmResults[id].Name);
              }
            }
            for (const fieldName of Object.keys(crmResults[id])) {
              if (ignoreFields.indexOf(fieldName) < 0) {
                recordItem.setField(fieldName, fieldName, fieldName, crmResults[id][fieldName]);
              }
            }
            result.addSearchRecord(recordItem);
          }
        }
      }
      this.loggerService.logger.logTrace('Salesforce - Home : END : Formatting CRM Results. CRM Results : ' + JSON.stringify(crmResults));
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Formatting CRM Results. CRM Results : ' + JSON.stringify(crmResults) + '. Error Information : ' + JSON.stringify(error));
    }
    return result;
  }

  protected createActivity(interaction: IInteraction): IActivity {
    try {
      this.loggerService.logger.logDebug('Salesforce - Home : START : Creating new Activity. Scenario ID : ' + interaction.scenarioId);
      const date = new Date();
      const activity: IActivity = {
        WhoObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: ''
        },
        WhatObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: ''
        },
        Subject: this.buildSubjectText(interaction),
        CallType: interaction.direction === INTERACTION_DIRECTION_TYPES.Inbound ? 'Inbound' : interaction.direction === INTERACTION_DIRECTION_TYPES.Outbound ? 'Outbound' : 'Internal',
        ChannelType: CHANNEL_TYPES[interaction.channelType],
        CallDurationInSeconds: 0,
        Description: '',
        Status: 'Open',
        ActivityDate: this.formatDate(date),
        TimeStamp: date,
        ActivityId: '',
        ScenarioId: interaction.scenarioId,
        TaskSubtype: this.buildTaskSubType(interaction),
        contactSource: this.getContactSource(interaction),
        CadFields: {},
        IsActive: true,
        IsProcessing: false,
        IsUnSaved: false,
        IsRecentWorkItemLoading: false,
        NumberOfHolds: 0,
        HoldDurationInSeconds: 0,
        HoldDurationOnInteractions: {},
        DurationOnInteractions: {},
        LastUpdated: date,
        IsSubjectChanged: false,
        InitialInteractionId: interaction.interactionId
      };
      for (const key in this.cadActivityMap) {
        if (interaction.details.fields[key] || interaction[key]) {
          if (!activity.CadFields) {
            activity.CadFields = {};
          }
          activity.CadFields[this.cadActivityMap[key]] = interaction.details.fields[key] ? interaction.details.fields[key].Value : interaction[key];
        }
      }
      this.storageService.setWhoEmptyRecord(interaction.scenarioId);
      this.storageService.setWhatEmptyRecord(interaction.scenarioId);
      this.loggerService.logger.logDebug('Salesforce - Home : New activity Info : ' + JSON.stringify(activity));
      this.loggerService.logger.logDebug('Salesforce - Home : END : Creating new Activity. Scenario ID : ' + interaction.scenarioId);
      return activity;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Creating new activity. Scenario ID : ' + interaction.scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected discardActivity(scenarioId) {
    let activity;
    try {
      this.loggerService.logger.logInformation('Salesforce  - Home : START : Discarding Activity. Scenario ID : ' + scenarioId);
      this.storageService.discardActivity(scenarioId);
    } catch (error) {
      this.storageService.activityList[scenarioId].IsProcessing = false;
      sendNotification('Error discarding activity.', NOTIFICATION_TYPE.Error);
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Discarding Activity. Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected async saveActivity(scenarioId, isComplete = false, saveToCRM = true): Promise<string> {
    const functionName = 'saveActivity';
    let activity;
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Saving Activity to CRM. Scenario ID : ' + scenarioId);
      activity = this.storageService.getActivity(scenarioId);

      this.storageService.updateTotalInteractionTime(scenarioId, this.cadActivityMap);
      this.storageService.setActivityField(scenarioId, 'IsActive', !isComplete);
      this.storageService.updateTotalHoldTime(scenarioId, this.cadActivityMap);

      if (!activity || !saveToCRM) {
        return;
      }

      activity.Status = isComplete ? 'Completed' : 'Not Completed';
      this.log(LOG_LEVEL.Trace, functionName, `Sending activity info to bridge for scenarioId: ${scenarioId}`, activity);
      activity = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.SAVE_ACTIVITY, activity);

      this.storageService.setActivityField(scenarioId, 'ActivityId', activity.ActivityId);

      this.log(LOG_LEVEL.Trace, functionName, `Received activity info to bridge for scenarioId: ${scenarioId}`, activity);

      activity = this.storageService.getActivity(scenarioId);

      // this.storageService.updateActivity(activity);
      this.storageService.activityList[scenarioId].IsProcessing = false;
      this.storageService.updateActivityFields(scenarioId);
      this.storageService.compareActivityFields(scenarioId);
      return Promise.resolve(activity.ActivityId);
    } catch (error) {
      this.storageService.activityList[scenarioId].IsProcessing = false;
      sendNotification('Error saving activity.', NOTIFICATION_TYPE.Error);
      this.log(LOG_LEVEL.Error, functionName, `Error saving activity for scenarioId: ${scenarioId}`, error);
    } finally {
      if (activity && saveToCRM) {
        this.sendActivityLMS(activity);
      }
    }
  }

  protected async getRecentWorkItem(scenarioId): Promise<void> {
    try {
      this.loggerService.logger.logInformation('Salesforce - Home : START : Recent Work Item Details from CRM. Scenario ID : ' + scenarioId);
      const activity = this.storageService.getActivity(scenarioId);
      const recentWorkItem = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.GET_ACTIVITY, activity);
      this.storageService.activityList[scenarioId].IsRecentWorkItemLoading = false;
      this.storageService.updateRecentWorkItem(recentWorkItem, scenarioId, this.activityLayout);

      this.loggerService.logger.logInformation('Salesforce - Home : END : Recent Work Item Details from CRM. Scenario ID : ' + scenarioId);
    } catch (error) {
      this.storageService.activityList[scenarioId].IsRecentWorkItemLoading = false;
      sendNotification('Error Retrieving Activity Details', NOTIFICATION_TYPE.Error);
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Recent Work Item Details from CRM. Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected agentSelectedCallerInformation(id: string) {
    this.loggerService.logger.logDebug('Salesforce - Home : START : Agent Selected Entity to screenpop. Entity ID : ' + id);
    this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.AGENT_SELECTED_CALLER_INFO, id);
    this.loggerService.logger.logDebug('Salesforce - Home : END : Agent Selected Entity to screenpop. Entity ID : ' + id);
  }

  protected async getActivityLayout() {
    try {
      this.loggerService.logger.logTrace('Salesforce - Home : START : Fetching Activity Layout');
      this.activityLayout = {};
      for (const item in CHANNEL_TYPES) {
        if (isNaN(Number(item))) {
          this.activityLayout[item] = {};
          this.activityLayout[item]['APIName'] = 'Task';
          this.activityLayout[item]['Fields'] = ['WhatId', 'WhoId', 'Subject', 'Description'];
          this.activityLayout[item]['LookupFields'] = {
            WhatId: 'WhatObject',
            WhoId: 'WhoObject'
          };
        }
      }
      this.loggerService.logger.logDebug('Salesforce - Home : Activity Layout information : ' + JSON.stringify(this.activityLayout));
      this.loggerService.logger.logTrace('Salesforce - Home : END : Fetching Activity Layout');
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Fetching Activity Layout. More information : ' + JSON.stringify(error));
    }
  }

  private overrideSoftphoneLayout(searchLayout) {
    let softphoneLayoutOverride = null;
    if (this.appConfig && this.appConfig['CallActivity'] && this.appConfig['CallActivity']['variables'] && this.appConfig['CallActivity']['variables']['SoftphoneLayoutOverride']) {
      softphoneLayoutOverride = this.appConfig['CallActivity']['variables']['SoftphoneLayoutOverride'];

      for (const key of Object.keys(searchLayout)) {
        if (key in softphoneLayoutOverride) {
          searchLayout[softphoneLayoutOverride[key]] = searchLayout[key];
          delete searchLayout[key];
        }
      }
    }

    return searchLayout;
  }

  protected async getSearchLayout() {
    try {
      this.loggerService.logger.logTrace('Salesforce - Home : START : Search Layout');
      const salesforceLayouts = await this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.GET_SEARCH_LAYOUT);

      for (const layout of Object.keys(salesforceLayouts)) {
        salesforceLayouts[layout].objects = this.overrideSoftphoneLayout(salesforceLayouts[layout].objects);
      }

      this.loggerService.logger.logDebug('Salesforce - Home : Received Search Layout information from Bridge : ' + JSON.stringify(salesforceLayouts));
      const result = new SearchLayouts();
      const telephonyLayout = new SearchLayout(false, []);
      if (salesforceLayouts.Internal) {
        telephonyLayout.setInternal(this.parseSearchLayoutEntities(salesforceLayouts.Internal));
      }
      if (salesforceLayouts.Inbound) {
        const openInNewWindow = salesforceLayouts.Inbound.screenPopSettings && salesforceLayouts.Inbound.screenPopSettings.screenPopOpenWithin && salesforceLayouts.Inbound.screenPopSettings.screenPopOpenWithin !== 'ExistingWindow';
        switch (salesforceLayouts.Inbound.screenPopSettings.NoMatch.screenPopType) {
          case 'PopToEntity':
            telephonyLayout.setNoMatch({
              type: NO_MATCH_POP_TYPES.PopToNewEntity,
              data: salesforceLayouts.Inbound.screenPopSettings.NoMatch.screenPopData
            });
            break;
          case 'DoNotPop':
            telephonyLayout.setNoMatch({
              type: NO_MATCH_POP_TYPES.NoPop
            });
            break;
          case 'PopToVisualforce':
            telephonyLayout.setNoMatch({
              type: NO_MATCH_POP_TYPES.PopToUrl,
              data: salesforceLayouts.Inbound.screenPopSettings.NoMatch.screenPopData
            });
        }
        switch (salesforceLayouts.Inbound.screenPopSettings.SingleMatch.screenPopType) {
          case 'PopToEntity':
            telephonyLayout.setSingleMatch({
              type: SINGLE_MATCH_POP_TYPES.PopToDetials
            });
            break;
          case 'DoNotPop':
            telephonyLayout.setSingleMatch({
              type: SINGLE_MATCH_POP_TYPES.NoPop
            });
            break;
          case 'PopToVisualforce':
            telephonyLayout.setSingleMatch({
              type: SINGLE_MATCH_POP_TYPES.PopToUrl,
              data: salesforceLayouts.Inbound.screenPopSettings.SingleMatch.screenPopData
            });
        }
        switch (salesforceLayouts.Inbound.screenPopSettings.MultipleMatches.screenPopType) {
          case 'PopToEntity':
            telephonyLayout.setMultiMatch({
              type: MULTI_MATCH_POP_TYPES.PopToSearch
            });
            break;
          case 'DoNotPop':
            telephonyLayout.setMultiMatch({
              type: MULTI_MATCH_POP_TYPES.NoPop
            });
            break;
          case 'PopToVisualforce':
            telephonyLayout.setMultiMatch({
              type: MULTI_MATCH_POP_TYPES.PopToUrl,
              data: salesforceLayouts.Inbound.screenPopSettings.MultipleMatches.screenPopData
            });
        }
        telephonyLayout.setInbound(this.parseSearchLayoutEntities(salesforceLayouts.Inbound));
        telephonyLayout.setDefault(telephonyLayout.getInbound());
        telephonyLayout.setOpenInNewWindow(openInNewWindow);
      }
      if (salesforceLayouts.Outbound) {
        telephonyLayout.setOutbound(this.parseSearchLayoutEntities(salesforceLayouts.Outbound));
      }
      result.setLayout([CHANNEL_TYPES.Telephony], telephonyLayout);
      this.loggerService.logger.logDebug('Salesforce - Home : Modified Search Layout Object : ' + JSON.stringify(result));
      this.loggerService.logger.logTrace('Salesforce - Home : END : Search Layout');
      return result;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Search Layout. More information : ' + JSON.stringify(error));
    }
  }

  protected getSecondsElapsed(startDate): number {
    try {
      this.loggerService.logger.logLoop('Salesforce - Home : START : Get Seconds Elapsed');
      const endDate = new Date();
      if (typeof startDate === 'string') {
        startDate = new Date(startDate);
      }
      this.loggerService.logger.logLoop('Salesforce - Home : END : Get Seconds Elapsed');
      return Math.round((endDate.getTime() - startDate.getTime()) / 1000);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Get Seconds Elapsed. Start Date : ' + startDate + '. Error Information : ' + JSON.stringify(error));
    }
  }

  @bind
  protected createNewEntity(entityType) {
    try {
      this.loggerService.logger.logTrace('Salesforce - Home : START : Quick Create Entity Type : ' + entityType);
      let params: ICreateNewSObjectParams;
      if (this.storageService.currentScenarioId) {
        if (this.storageService.activityList[this.storageService.currentScenarioId]) {
          const activity = this.storageService.getActivity(this.storageService.currentScenarioId);
          params = this.buildParams(entityType, activity);
          params['CAD'] = this.storageService.getCadFields();
        }
      } else {
        params = this.buildParams(entityType, null);
      }
      this.loggerService.logger.logDebug('Salesforce - Home : Quick create request to bridge with params : ' + JSON.stringify(params));
      this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.CREATE_NEW_ENTITY, params);
      this.loggerService.logger.logTrace('Salesforce - Home : END : Quick Create Entity Type : ' + entityType);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Quick Create. Entity Type : ' + entityType + '. More Info : ' + JSON.stringify(error));
    }
  }

  protected onFocusHandler(entity) {
    try {
      this.loggerService.logger.logDebug('Salesforce - Home : START : Received On Focus Event. Event Info : ' + JSON.stringify(entity));

      // Convert display name to proper name
      if (this.apiToProperName && entity.displayName in this.apiToProperName) {
        entity.displayName = this.apiToProperName[entity.objectType];
      }

      if (this.storageService.currentScenarioId || entity.hasOwnProperty('AddToList')) {
        this.storageService.updateWhoWhatLists(entity, this.storageService.currentScenarioId);
      }
      if (this.storageService.workingRecentScenarioId) {
        this.storageService.updateWhoWhatLists(entity, this.storageService.workingRecentScenarioId);
      }
      this.loggerService.logger.logDebug('Salesforce - Home : END : Received On Focus Event. Event Info : ' + JSON.stringify(entity));
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : On Focus Event. Received Entity info : ' + JSON.stringify(entity) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected isToolbarVisible(): Promise<boolean> {
    return this.bridgeEventsService.sendEvent(SF_BRIDGE_EVTS.IS_TOOLBAR_VISIBLE);
  }

  @bind
  protected sendNotification(event: any) {
    sendNotification(event.notification, event.NOTIFICATION_TYPE);
  }

  @bind
  protected clickToDialHandler(event: any) {
    try {
      this.loggerService.logger.logDebug('Salesforce - Home : START: Click to Dial Event : ' + JSON.stringify(event));
      let phoneNum = '';
      let objectForFormatCrmResults = {};

      if (event.isLightning) {
        // Restructure to Contact if it is a Person Account
        if (event.entity.personAccount === true && event.entity.contactId) {
          event.entity.recordId = event.entity.contactId;
          event.entity.objectType = 'Contact';
        }

        // "IsPersonAccount" is necessary to let UI know to show "Person Account" instead of "Contact"
        objectForFormatCrmResults = {
          [event.entity.recordId]: {
            Id: event.entity.recordId,
            Name: event.entity.recordName,
            RecordType: event.entity.objectType,
            IsPersonAccount: event.entity.personAccount === true ? true : false
          }
        };
        phoneNum = event.entity.number;
        const numberToDial = this.clickToDialFormatPhoneNumber(phoneNum).outputNumber;
        this.setSoftphonePanelVisibility(true);
        clickToDial(numberToDial, this.formatCrmResults(objectForFormatCrmResults));
      } else {
        const classicEntity = JSON.parse(event.entity.result);

        // Restructure to Contact if it is a Person Account
        if (classicEntity.personAccount === true && classicEntity.contactId) {
          classicEntity.objectId = classicEntity.contactId;
          classicEntity.object = 'Contact';
        }

        // "IsPersonAccount" is necessary to let UI know to show "Person Account" instead of "Contact"
        objectForFormatCrmResults = {
          [classicEntity.objectId]: {
            Id: classicEntity.objectId,
            Name: classicEntity.objectName,
            RecordType: classicEntity.object,
            IsPersonAccount: classicEntity.personAccount === true ? true : false
          }
        };

        phoneNum = classicEntity.number;
        const numberToDial = this.clickToDialFormatPhoneNumber(phoneNum).outputNumber;
        this.setSoftphonePanelVisibility(true);
        clickToDial(numberToDial, this.formatCrmResults(objectForFormatCrmResults));
      }

      const formattedPhoneNumber = this.clickToDialFormatPhoneNumber(phoneNum);

      this.clickToDialList[formattedPhoneNumber.outputNumber] = {
        entity: event.entity,
        alternateNumbers: this.generateAlternateNumbers(phoneNum)
      };

      this.ctdWhoWhatList[formattedPhoneNumber.outputNumber] = {
        entity: objectForFormatCrmResults[Object.keys(objectForFormatCrmResults)[0]],
        alternateNumbers: [formattedPhoneNumber.plainNumber]
      };

      if (event.lastOnFocusWasAnEntity) {
        this.lastOnFocusWasAnEntityList.push(formattedPhoneNumber.outputNumber);
      }

      this.loggerService.logger.logDebug('Salesforce - Home : END: Click to Dial Event : ' + JSON.stringify(event));
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Click to Dial Event. Event Info : ' + JSON.stringify(event) + '. Error Info : ' + JSON.stringify(error));
    }
  }

  /**
   * Produces a list of potential alternate formats that the given number can appear as, given the phone number format configs.
   * This is not enough to generate ALL possibilities, because there may be no way to reach format A through format B (if there is no
   * reformatting rule that maps this transformation). Because of this, we must also check the RAW incoming number to see if any of its
   * formats can match to the click to dial record as well.
   *
   *
   * @param number
   * @returns
   */
  private generateAlternateNumbers(number: string): string[] {
    const ret = [number];

    let lastLength = 0;

    while (lastLength < ret.length) {
      lastLength = ret.length;

      for (const num of ret) {
        const formatted = this.clickToDialFormatPhoneNumber(num).outputNumber;

        if (!ret.includes(formatted)) {
          ret.push(formatted);
        }

        const formatted2 = this.formatPhoneNumber(num, this.phoneNumberFormat);

        if (!ret.includes(formatted2)) {
          ret.push(formatted2);
        }
      }
    }

    return ret;
  }

  protected getContactSource(interaction: IInteraction) {
    this.loggerService.logger.logTrace('Salesforce - Home : START : Get Contact Source. Interaction Info : ' + JSON.stringify(interaction));
    let contactSource = { sourceType: 'Name', source: '' };
    try {
      if (interaction.details.fields) {
        const fields = interaction.details.fields;
        if (fields.Email) {
          contactSource = { sourceType: 'Email', source: fields.Email.Value };
        } else if (fields.Phone) {
          contactSource = { sourceType: 'Phone', source: fields.Phone.Value };
        } else if (fields.FullName) {
          contactSource = { sourceType: 'Name', source: fields.FullName.Value };
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Get Contact Source. Interaction Info : ' + JSON.stringify(interaction) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logDebug('Salesforce - Home : Contact Source for interaction with Scenario ID : ' + interaction.scenarioId + ' is ' + JSON.stringify(contactSource));
    this.loggerService.logger.logTrace('Salesforce - Home : END : Get Contact Source. Interaction Info : ' + JSON.stringify(interaction));
    return contactSource;
  }

  protected buildParams(entityType: string, activity: IActivity) {
    this.loggerService.logger.logTrace('Salesforce - Home : START : Building parameters for Quick Create. Entity Type : ' + entityType + '. Activity Info : ' + JSON.stringify(activity));
    const params: ICreateNewSObjectParams = {
      entityName: entityType,
      caseFields: {},
      opportunityFields: {},
      leadFields: {}
    };
    try {
      if (this.storageService.currentScenarioId) {
        if (entityType === 'Case') {
          if (activity.WhatObject.objectType === 'Account') {
            params.caseFields.AccountId = activity.WhatObject.objectId;
          }
          if (activity.WhoObject.objectType === 'Contact') {
            params.caseFields.ContactId = activity.WhoObject.objectId;
          }
          params.caseFields.Description = activity.Description;
        } else if (entityType === 'Opportunity') {
          if (activity.WhatObject.objectType === 'Account') {
            params.opportunityFields.AccountId = activity.WhatObject.objectId;
          }
          params.opportunityFields.CloseDate = activity.ActivityDate;
          params.opportunityFields.Description = activity.Description;
          params.opportunityFields.StageName = 'Prospecting';
        } else if (entityType === 'Lead') {
          params.leadFields[this.storageService.activityList[this.storageService.currentScenarioId].contactSource.sourceType] = this.storageService.activityList[this.storageService.currentScenarioId].contactSource.source;
          params.leadFields.Description = activity.Description;
        }
      }
      this.loggerService.logger.logTrace('Salesforce - Home : END : Building parameters for Quick Create. Entity Type : ' + entityType + '. Activity Info : ' + JSON.stringify(activity));
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Building Parameters for Quick Create. Entity Type : ' + entityType + '. Activity Info : ' + JSON.stringify(activity) + '. Error Info : ' + JSON.stringify(error));
    }
    return params;
  }

  private parseSearchLayoutEntities(salesforceLayout: any): ISearchLayoutForEntity[] {
    this.loggerService.logger.logTrace('Salesforce - Home : START : Parse Search Layout. Info : ' + JSON.stringify(salesforceLayout));
    const layoutsForEntities: ISearchLayoutForEntity[] = [];
    try {
      for (const entityName of Object.keys(salesforceLayout.objects)) {
        const layoutForEntity: ISearchLayoutForEntity = {
          DisplayName: entityName,
          DevName: entityName,
          DisplayFields: [],
          PhoneFields: [],
          EmailFields: [],
          SocialFields: [],
          NameFields: []
        };

        for (const field of Object.values<{ apiName; displayName }>(salesforceLayout.objects[entityName])) {
          layoutForEntity.DisplayFields.push({
            DevName: field.apiName,
            DisplayName: field.displayName,
            Value: null
          });
        }

        layoutsForEntities.push(layoutForEntity);
      }
      this.loggerService.logger.logDebug('Salesforce - Home : Parse Search Layout. Input Layout : ' + JSON.stringify(salesforceLayout) + '. Modified Layout : ' + JSON.stringify(layoutsForEntities));
      this.loggerService.logger.logTrace('Salesforce - Home : END : Parse Search Layout. Info : ' + JSON.stringify(salesforceLayout));
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Parse Search Layout. Input Layout : ' + JSON.stringify(salesforceLayout));
    }
    return layoutsForEntities;
  }

  protected formatDate(date: Date): string {
    try {
      this.loggerService.logger.logLoop('Salesforce - Home : START : Format Date. Input Date : ' + date);
      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();
      const year = '' + date.getFullYear();
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      this.loggerService.logger.logLoop('Salesforce - Home : END : Format Date. Input Date : ' + date);
      return year + '-' + month + '-' + day;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Home : ERROR : Format Date. Input Date : ' + date + '. Error Information : ' + JSON.stringify(error));
    }
  }
}
