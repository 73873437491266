import { ERROR_CODE, IField, INTERACTION_DIRECTION_TYPES, IRecordItem, LOG_LEVEL } from '@amc-technology/davinci-api';

import { IActivity } from './Model/Interfaces/IActivity';
import { IActivityDetails } from './Model/Interfaces/IActivityDetails';
import { IActivityFields } from './Model/Interfaces/IActivityFields';
import { IInteraction } from '@amc-technology/davinci-api';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { Subject } from 'rxjs';

@Injectable()
export class StorageService {
  log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) => void;

  public whoList: {
    [scenarioId: string]: IActivityDetails[];
  };

  public whatList: {
    [scenarioId: string]: IActivityDetails[];
  };

  public currentScenarioId: string;
  public currentScenarioCallType: string;
  public workingRecentScenarioId: string;
  public activityList: {
    [scenarioId: string]: IActivity;
  };

  public currentTicketId: string;
  public recentScenarioIdList: string[];
  public activeScenarioIdList: string[];
  public expiredScenarioIdList: string[];
  public searchRecordList: {
    [scenarioId: string]: IRecordItem[];
  };

  public selectedWhatValueList: {
    [key: string]: string;
  };

  public selectedWhoValueList: {
    [key: string]: string;
  };

  public selectedSearchRecordList: {
    [key: string]: string;
  };

  public nameChangesList: string[];
  public relatedToChangesList: string[];
  public maxExpiredItems: Number;
  public maxRecentItems: number;
  public scenarioToCADMap: {
    [scenarioId: string]: {
      [key: string]: IField;
    };
  };

  public scenarioToCallerInfoMap: {
    [scenarioId: string]: any;
  };

  public emptyIActivityDetails: IActivityDetails;
  public savedActivityFields: {
    [scenarioId: string]: IActivityFields;
  };

  public nameObjects: string[];
  public currentTimer: number;
  public isTabbedUI: boolean;
  public tabbedActivityList;
  public tabName: string;
  public tabsObject: any[];
  clickSubject: Subject<any> = new Subject();

  constructor(private loggerService: LoggerService) {
    this.log = loggerService.log;
    this.whoList = {};
    this.whatList = {};
    this.currentScenarioId = null;
    this.currentScenarioCallType = null;
    this.workingRecentScenarioId = null;
    this.activityList = {};
    this.recentScenarioIdList = [];
    this.activeScenarioIdList = [];
    this.expiredScenarioIdList = [];
    this.searchRecordList = {};
    this.selectedWhatValueList = {};
    this.selectedWhoValueList = {};
    this.selectedSearchRecordList = {};
    this.nameChangesList = [];
    this.relatedToChangesList = [];
    this.maxRecentItems = 0;
    this.maxExpiredItems = 2;
    this.currentTicketId = '';
    this.scenarioToCADMap = {};
    this.scenarioToCallerInfoMap = {};
    this.emptyIActivityDetails = {
      displayName: '',
      objectId: '',
      objectName: '',
      objectType: '',
      url: ''
    };
    this.savedActivityFields = {};
    this.nameObjects = [];
    this.currentTimer = -1;
    this.isTabbedUI = false;
    this.tabName = '';
    this.tabsObject = [];
    this.tabbedActivityList = [];
  }

  public setNameObjects(nameObjects: string[]) {
    this.nameObjects = nameObjects;
  }

  public updateTotalInteractionTime(scenarioId: string, cadActivityMap: Object) {
    try {
      if (this.activityList[scenarioId]) {
        let activityModified = false;

        // Close any interacions that are not disconnected
        for (const i of Object.keys(this.activityList[scenarioId].DurationOnInteractions)) {
          this.updateInteractionDurationActivity(scenarioId, i, true);
        }

        if (this.activityList[scenarioId].DurationOnInteractions) {
          this.activityList[scenarioId].CallDurationInSeconds = 0;
          for (const i of Object.keys(this.activityList[scenarioId].DurationOnInteractions)) {
            if (this.activityList[scenarioId].DurationOnInteractions[i].interactionDuration) {
              this.activityList[scenarioId].CallDurationInSeconds += this.activityList[scenarioId].DurationOnInteractions[i].interactionDuration;
              activityModified = true;
            }
          }
        }

        if (activityModified) {
          this.storeToLocalStorage();
          // Limit string data to 255 characters;
          this.updateActivityCadField(scenarioId, cadActivityMap, 'DetailedCallDuration', this.constrainString(JSON.stringify(this.activityList[scenarioId].DurationOnInteractions), 255));
          this.updateActivityCadField(scenarioId, cadActivityMap, 'CallDuration', this.activityList[scenarioId].CallDurationInSeconds.toString());
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SalesForce - Storage : ERROR : updateTotalInteractionTime for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private constrainString(str: string, limit: number): string {
    return str.length > limit ? str.substr(0, limit) : str;
  }

  public updateInteractionDurationActivity(scenarioId: string, interactionId: string, isDisconnected: boolean) {
    try {
      if (this.activityList[scenarioId]) {
        let activityModified = false;
        if (!this.activityList[scenarioId].DurationOnInteractions || !this.activityList[scenarioId].DurationOnInteractions[interactionId]) {
          if (!isDisconnected) {
            // If the interaction is Not Disconnected for the first time
            this.activityList[scenarioId].DurationOnInteractions[interactionId] = {
              interactionDuration: 0,
              DurationTracking: []
            };

            this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[0] = {
              start: new Date(),
              end: undefined
            };

            activityModified = true;
            this.loggerService.logger.logDebug('SalesForce - Storage : updateInteractionDurationActivity First time for Scenario ID : ' + scenarioId + ' InteractionId : ' + interactionId);
          } // else ignore since the call connected didn't come yet or this must be an extra disconnect
        } else {
          // Interaction was strted earlier
          const trackingLength = this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking.length;
          if (isDisconnected && trackingLength > 0) {
            if (
              !this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end &&
              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start
            ) {
              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end = new Date();
              if (typeof this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start === 'string') {
                // If Start Date got loaded from localstorage then, convert it back to Date
                this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start = new Date(
                  this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start
                );
              }

              if (!this.activityList[scenarioId].DurationOnInteractions[interactionId].interactionDuration) {
                this.activityList[scenarioId].DurationOnInteractions[interactionId].interactionDuration = 0;
              }

              this.activityList[scenarioId].DurationOnInteractions[interactionId].interactionDuration += Math.round(
                (this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end.getTime() -
                  this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start.getTime()) /
                  1000
              );

              activityModified = true;
              this.loggerService.logger.logDebug('SalesForce - Storage : updateInteractionDurationActivity call Disconnected for Scenario ID : ' + scenarioId + ' InteractionId : ' + interactionId);
            }
          }

          if (!isDisconnected) {
            if (
              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end &&
              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start
            ) {
              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength] = {
                start: new Date(),
                end: undefined
              };

              activityModified = true;
              this.loggerService.logger.logDebug('SalesForce - Storage : updateInteractionDurationActivity call started Scenario ID : ' + scenarioId + ' InteractionId : ' + interactionId);
            }
          }
        }

        if (activityModified) {
          this.storeToLocalStorage();
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SalesForce - Storage : ERROR : updateInteractionDurationActivity for Scenario ID : ' + scenarioId + ' InteractionId : ' + interactionId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateTotalHoldTime(scenarioId: string, cadActivityMap: Object) {
    try {
      if (this.activityList[scenarioId]) {
        let activityModified = false;

        // Close any Hold interacions
        for (const i of Object.keys(this.activityList[scenarioId].HoldDurationOnInteractions)) {
          this.updateHoldInteractionActivityField(scenarioId, i, false);
        }

        if (this.activityList[scenarioId].HoldDurationOnInteractions) {
          this.activityList[scenarioId].HoldDurationInSeconds = 0;
          this.activityList[scenarioId].NumberOfHolds = 0;
          for (const i of Object.keys(this.activityList[scenarioId].HoldDurationOnInteractions)) {
            if (this.activityList[scenarioId].HoldDurationOnInteractions[i].holdDuration) {
              this.activityList[scenarioId].HoldDurationInSeconds += this.activityList[scenarioId].HoldDurationOnInteractions[i].holdDuration;
              activityModified = true;
            }

            if (this.activityList[scenarioId].HoldDurationOnInteractions[i].numberOfHolds) {
              this.activityList[scenarioId].NumberOfHolds += this.activityList[scenarioId].HoldDurationOnInteractions[i].numberOfHolds;
              activityModified = true;
            }
          }
        }

        if (activityModified) {
          this.storeToLocalStorage();
          this.updateActivityCadField(scenarioId, cadActivityMap, 'DetailedHoldDuration', this.constrainString(JSON.stringify(this.activityList[scenarioId].HoldDurationOnInteractions), 255));
          this.updateActivityCadField(scenarioId, cadActivityMap, 'HoldDurationInSeconds', this.activityList[scenarioId].HoldDurationInSeconds.toString());
          this.updateActivityCadField(scenarioId, cadActivityMap, 'NumberOfHolds', this.activityList[scenarioId].NumberOfHolds.toString());
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SalesForce - Storage : ERROR : updateTotalHoldTime for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateHoldInteractionActivityField(scenarioId: string, interactionId: string, isHold: boolean) {
    try {
      if (this.activityList[scenarioId]) {
        let activityModified = false;
        if (!this.activityList[scenarioId].HoldDurationOnInteractions || !this.activityList[scenarioId].HoldDurationOnInteractions[interactionId]) {
          if (isHold) {
            // If the interaction is on hold for the first time
            this.activityList[scenarioId].HoldDurationOnInteractions[interactionId] = {
              numberOfHolds: 0,
              holdDuration: 0,
              holdDurationTracking: []
            };

            this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[0] = {
              start: new Date(),
              end: undefined
            };

            activityModified = true;
            this.loggerService.logger.logDebug('SalesForce - Storage : updateHoldInteractionActivityField call OnHold First time for Scenario ID : ' + scenarioId + ' InteractionId : ' + interactionId + ' isHold ' + isHold);
          } // else ignore since the call never went to hold
        } else {
          // Interaction was atleast once on OnHold before
          const trackingLength = this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking.length;
          if (!isHold && trackingLength > 0) {
            if (
              !this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end &&
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start
            ) {
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end = new Date();
              if (typeof this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start === 'string') {
                // If Start Date got loaded from local storage then, convert it back to Date
                this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start = new Date(
                  this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start
                );
              }

              if (!this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration) {
                this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration = 0;
              }

              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration += Math.round(
                (this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end.getTime() -
                  this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start.getTime()) /
                  1000
              );

              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].numberOfHolds++;
              activityModified = true;
              this.loggerService.logger.logDebug(
                'SalesForce - Storage : updateHoldInteractionActivityField call Not OnHold for Scenario ID : ' + scenarioId + ' InteractionId : ' + interactionId + ' Number of Holds  ' + trackingLength + ' isHold ' + isHold
              );
            }
          }

          if (isHold) {
            if (
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end &&
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start
            ) {
              // Make sure we take into account we handle multiple HOLD events when call is OnHOLD but we only track once
              // Once when the last HOLD / Retrieve are complete we take on next HOLD event as next OnHOLD occurrence
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength] = {
                start: new Date(),
                end: undefined
              };

              activityModified = true;
              this.loggerService.logger.logDebug(
                'SalesForce - Storage : updateHoldInteractionActivityField call OnHold for Scenario ID : ' + scenarioId + ' InteractionId : ' + interactionId + ' Number of Holds  ' + trackingLength + ' isHold ' + isHold
              );
            }
          }
        }

        if (activityModified) {
          this.storeToLocalStorage();
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SalesForce - Storage : ERROR : updateHoldInteractionActivityField for Scenario ID : ' + scenarioId + ' InteractionId : ' + interactionId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateActivityCadField(scenarioId: string, cadActivityMap: Object, cadKey: string, cadValue: string) {
    if (cadActivityMap && cadActivityMap[cadKey]) {
      const objActivity = this.getActivity(scenarioId);
      if (!objActivity.CadFields) {
        objActivity.CadFields = {};
      }
      objActivity.CadFields[cadActivityMap[cadKey]] = cadValue;
      this.updateActivity(objActivity);
    }
  }

  public setCurrentScenarioId(currentScenarioId: string, callType?: INTERACTION_DIRECTION_TYPES) {
    try {
      this.currentScenarioId = currentScenarioId;
      this.currentScenarioCallType = callType === undefined ? null : callType === INTERACTION_DIRECTION_TYPES.Inbound ? 'Inbound' : callType === INTERACTION_DIRECTION_TYPES.Outbound ? 'Outbound' : 'Internal';
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set Current Scenario ID for Scenario ID : ' + currentScenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public getActivity(scenarioId: string = null): IActivity {
    const functionName = 'getActivity';
    try {
      if (!scenarioId) {
        this.log(LOG_LEVEL.Trace, functionName, `Getting current activity for scenarioId: ${this.currentScenarioId}`);
        scenarioId = this.currentScenarioId;
      }
      this.log(LOG_LEVEL.Debug, functionName, `Getting activity for scenarioId: ${scenarioId}`);
      if (this.activityList[scenarioId]) {
        return this.activityList[scenarioId];
      }
      return null;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, `Error getting activity for scenarioId: ${scenarioId}`, error);
    }
  }

  public recentActivityListContains(scenarioId: string): boolean {
    try {
      return this.activityList[scenarioId] && !this.activityList[scenarioId].IsActive ? true : false;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Check if Exists in Recent Activity List for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private addRecentActivity(activity: IActivity, isAutoSave: boolean) {
    const functionName = 'addRecentActivity';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Adding activity to recent activities.', activity);
      const deleteExpiredActivity = this.expiredScenarioIdList.length === this.maxExpiredItems && this.maxRecentItems === 0;
      if (Object.keys(this.recentScenarioIdList).length >= this.maxRecentItems || this.maxRecentItems === 0) {
        const scenarioId = this.maxRecentItems === 0 ? (deleteExpiredActivity ? this.expiredScenarioIdList.pop() : activity.ScenarioId) : this.recentScenarioIdList.pop();
        this.clearWhatList(scenarioId);
        this.clearWhoList(scenarioId);
        delete this.selectedWhatValueList[scenarioId];
        delete this.selectedWhoValueList[scenarioId];
        if (this.maxRecentItems !== 0 || deleteExpiredActivity) {
          delete this.savedActivityFields[scenarioId];
          delete this.activityList[scenarioId];
        }
      }
      if (this.maxRecentItems !== 0) {
        this.recentScenarioIdList.unshift(activity.ScenarioId);
        if (this.activityList[activity.ScenarioId].IsUnSaved && !isAutoSave) {
          this.workingRecentScenarioId = activity.ScenarioId;
        }
      } else {
        this.expiredScenarioIdList.unshift(activity.ScenarioId);
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error adding activity to recent activities.', error);
    }
  }

  public addActivity(activity: IActivity) {
    try {
      this.activityList[activity.ScenarioId] = activity;
      this.activeScenarioIdList.push(activity.ScenarioId);
      this.storeToLocalStorage();
      this.tabsObject = [];
      this.tabbedActivityList.forEach((tab) => {
        tab.content = [];
      });
      // Clear all objects of each bucket
      if (this.tabbedActivityList.length > 0) {
        if (this.activeScenarioIdList.length > 0) {
          this.activeScenarioIdList.forEach((scenario) => {
            const obj = new Object();
            obj['scenario'] = scenario;
            this.tabbedActivityList.forEach((tab) => {
              if (tab.tabName === scenario.substring(0, tab.tabName.length)) {
                let iconName = '';
                if (this.activityList[scenario].ChannelType === 'Telephony') {
                  iconName = 'assets/images/call.png';
                } else if (this.activityList[scenario].ChannelType === 'Email') {
                  iconName = 'assets/images/emailTab.png';
                } else {
                  iconName = 'assets/images/chat.png';
                }
                const activityObj = {
                  scenario: scenario,
                  ActivityDetails: this.activityList[scenario],
                  WhoObjectList: this.whoList[scenario],
                  WhatObjectList: this.whatList[scenario],
                  scenarioToCADMap: this.scenarioToCADMap[scenario],
                  title: this.activityList[scenario].contactSource.source,
                  iconName: iconName
                };
                tab.content.push(activityObj);
              }
            });
          });
          this.tabbedActivityList.forEach((tab) => {
            if (tab.tabName === this.tabName) {
              this.tabsObject.push(tab.content);
            }
          });
        }
      }
      setTimeout(() => {
        this.clickSubject.next(activity.ScenarioId);
      }, 30000);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Add Activity for Scenario ID : ' + activity.ScenarioId + ', Activity Info : ' + JSON.stringify(activity) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateActivity(activity: IActivity) {
    try {
      if (this.activityList[activity.ScenarioId]) {
        activity.LastUpdated = new Date();
        this.activityList[activity.ScenarioId] = activity;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Update Activity for Scenario ID : ' + activity.ScenarioId + ', Activity Info : ' + JSON.stringify(activity) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public discardActivity(scenarioId: string) {
    try {
      this.tabsObject = [];
      this.tabbedActivityList.forEach((tab) => {
        tab.content = [];
      });
      if (this.tabbedActivityList.length > 0) {
        if (this.activeScenarioIdList.length > 0) {
          this.activeScenarioIdList.forEach((scenario) => {
            const obj = new Object();
            obj['scenario'] = scenario;
            this.tabbedActivityList.forEach((tab) => {
              if (tab.tabName === scenario.substring(0, tab.tabName.length)) {
                let iconName = '';
                if (this.activityList[scenario].ChannelType === 'Telephony') {
                  iconName = 'assets/images/call.png';
                } else if (this.activityList[scenario].ChannelType === 'Email') {
                  iconName = 'assets/images/emailTab.png';
                } else {
                  iconName = 'assets/images/chat.png';
                }
                const activityObj = {
                  scenario: scenario,
                  ActivityDetails: this.activityList[scenario],
                  WhoObjectList: this.whoList[scenario],
                  WhatObjectList: this.whatList[scenario],
                  scenarioToCADMap: this.scenarioToCADMap[scenario],
                  title: this.activityList[scenario].contactSource.source,
                  iconName: iconName
                };
                tab.content.push(activityObj);

                if (tab.content.length > 0) {
                  for (let i = 0; i < tab.content.length; i++) {
                    if (tab.content[i].scenario === scenarioId) {
                      if (this.searchRecordList[scenarioId]) {
                        delete tab.content[i].ActivityDetails;
                        delete tab.content[i].WhoObjectList;
                        delete tab.content[i].WhatObjectList;
                        delete tab.content[i].scenarioToCADMap;
                      } else {
                        tab.content[i] = [];
                      }
                    }
                  }
                }
              }
            });
          });
          this.tabbedActivityList.forEach((tab) => {
            if (tab.tabName === this.tabName) {
              this.tabsObject.push(tab.content);
            }
          });
          this.tabsObject = [...this.tabsObject].reverse();
        }
      }

      if (this.activityList[scenarioId]) {
        this.activeScenarioIdList = this.activeScenarioIdList.filter((id) => id !== scenarioId);
        this.recentScenarioIdList = this.recentScenarioIdList.filter((id) => id !== scenarioId);
      } else {
        this.clearWhoList(scenarioId);
        this.clearWhatList(scenarioId);
      }
      this.currentScenarioId = this.activeScenarioIdList[this.activeScenarioIdList.length - 1];
      this.currentScenarioCallType = this.activityList[this.currentScenarioId]['CallType'];
      this.workingRecentScenarioId = null;
      delete this.activityList[scenarioId];
      delete this.recentScenarioIdList[scenarioId];
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Remove Activity for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setCurrentTimer(scenarioId: string, currentTimer: number) {
    try {
      if (this.activityList[scenarioId]) {
        this.activityList[scenarioId]['currentTimer'] = currentTimer;
        this.currentTimer = currentTimer;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set Activity Field for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public getPreviousTimer(): number {
    return JSON.parse(localStorage.getItem('scenario'))['currentTimer'];
  }

  private removeActivity(scenarioId: string, isAutoSave: boolean) {
    try {
      if (this.activityList[scenarioId]) {
        this.addRecentActivity(this.activityList[scenarioId], isAutoSave);
        this.activeScenarioIdList = this.activeScenarioIdList.filter((id) => id !== scenarioId);
      } else {
        this.clearWhoList(scenarioId);
        this.clearWhatList(scenarioId);
      }
      this.storeToLocalStorage();
      this.tabsObject = [];
      this.tabbedActivityList.forEach((tab) => {
        tab.content = [];
      });
      // Clear all objects of each bucket
      if (this.tabbedActivityList.length > 0) {
        // Creates an object for each item in scenarioIdList in the right bucket
        if (this.activeScenarioIdList.length > 0) {
          this.activeScenarioIdList.forEach((scenario) => {
            const obj = new Object();
            obj['scenario'] = scenario;
            this.tabbedActivityList.forEach((tab) => {
              if (tab.tabName === scenario.substring(0, tab.tabName.length)) {
                let iconName = '';
                if (this.activityList[scenario].ChannelType === 'Telephony') {
                  iconName = 'assets/images/call.png';
                } else if (this.activityList[scenario].ChannelType === 'Email') {
                  iconName = 'assets/images/emailTab.png';
                } else {
                  iconName = 'assets/images/chat.png';
                }
                const activityObj = {
                  scenario: scenario,
                  ActivityDetails: this.activityList[scenario],
                  WhoObjectList: this.whoList[scenario],
                  WhatObjectList: this.whatList[scenario],
                  scenarioToCADMap: this.scenarioToCADMap[scenario],
                  title: this.activityList[scenario].contactSource.source,
                  iconName: iconName
                };
                tab.content.push(activityObj);
              }
            });
          });
        }
        this.tabbedActivityList.forEach((tab) => {
          if (tab.tabName === this.tabName) {
            this.tabsObject.push(tab.content);
          }
        });
        this.tabsObject = [...this.tabsObject].reverse();
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Remove Activity for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private activityListContains(scenarioId: string): boolean {
    try {
      return this.activityList[scenarioId] ? true : false;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Check if Exists in Activity List for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public getSubject(): string {
    try {
      return this.getActivity().Subject;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Get Subject. Error Information : ' + JSON.stringify(error));
    }
  }

  public setSubject(subject: string, scenarioId: string) {
    try {
      if (this.activityList[scenarioId]) {
        this.activityList[scenarioId].Subject = subject;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set Subject for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public getDescription(): string {
    try {
      return this.getActivity().Description;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Get Description. Error Information : ' + JSON.stringify(error));
    }
  }

  public setDescription(description: string, scenarioId: string) {
    const functionName = 'setDescription';
    try {
      if (this.activityList[scenarioId]) {
        this.log(LOG_LEVEL.Trace, functionName, `Setting description for scenarioId: ${scenarioId} to ${description}`);
        this.activityList[scenarioId].Description = description;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, `Error setting description for scenarioId: ${scenarioId} to ${description}`, error);
    }
  }

  public setActivityField(scenarioId: string, activityField: string, activityValue: any) {
    try {
      if (this.activityList[scenarioId]) {
        this.activityList[scenarioId][activityField] = activityValue;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set Activity Field for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private setActivityWhoObject(whoObject: IActivityDetails, scenarioId: string) {
    try {
      if (this.activityList[scenarioId]) {
        this.activityList[scenarioId].WhoObject = whoObject;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set Activity Who Object for Scenario ID : ' + scenarioId + ', What Object : ' + JSON.stringify(whoObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private setActivityWhatObject(whatObject: IActivityDetails, scenarioId: string) {
    try {
      if (this.activityList[scenarioId]) {
        this.activityList[scenarioId].WhatObject = whatObject;
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set Activity What Object for Scenario ID : ' + scenarioId + ', What Object : ' + JSON.stringify(whatObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public UpdateWhoObjectSelectionChange(whoObjectId: string, scenarioId: string) {
    try {
      const currentWhoObject = this.getWhoObject(whoObjectId, scenarioId);
      if (this.currentScenarioId === scenarioId) {
        this.nameChangesList.push(scenarioId);
      }
      if (currentWhoObject.objectType.toUpperCase() === 'LEAD') {
        this.selectedWhatValueList[scenarioId] = this.emptyIActivityDetails.objectId;
        this.setActivityWhatObject(this.emptyIActivityDetails, scenarioId);
      }
      this.setActivityWhoObject(currentWhoObject, scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Update Who Object Selection Change for Scenario ID : ' + scenarioId + ', Who Object ID : ' + whoObjectId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public UpdateWhatObjectSelectionChange(whatObjectId: string, scenarioId: string) {
    try {
      const currentWhatObject = this.getWhatObject(whatObjectId, scenarioId);
      if (this.currentScenarioId === scenarioId) {
        this.relatedToChangesList.push(scenarioId);
      }
      this.setActivityWhatObject(currentWhatObject, scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Update What Object Selection Change for Scenario ID : ' + scenarioId + ', What Object ID : ' + whatObjectId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private getWhatObject(whatId: string, scenarioId: string): IActivityDetails {
    try {
      // if (whatId === 'UserSelectedForEmptyRecord') {
      //   return this.emptyIActivityDetails;
      // }
      return this.whatList[scenarioId].find((item) => item.objectId === whatId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Get What Object for Scenario ID : ' + scenarioId + ', What Id : ' + whatId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private getWhoObject(whoId: string, scenarioId: string): IActivityDetails {
    try {
      // if (whoId === 'UserSelectedForEmptyRecord') {
      //   return this.emptyIActivityDetails;
      // }
      return this.whoList[scenarioId].find((item) => item.objectId === whoId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Get Who Object for Scenario ID : ' + scenarioId + ', Who Id : ' + whoId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setWhoEmptyRecord(scenarioId: string) {
    try {
      this.setWhoList(this.emptyIActivityDetails, scenarioId);
      this.selectedWhoValueList[scenarioId] = '';
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set Who Empty Record for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setWhatEmptyRecord(scenarioId: string) {
    try {
      this.setWhatList(this.emptyIActivityDetails, scenarioId);
      this.selectedWhatValueList[scenarioId] = '';
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set What Empty Record for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private whatListContains(whatObject: IActivityDetails, scenarioId: string): boolean {
    try {
      if (scenarioId) {
        const interactionWhatList = this.whatList[scenarioId];
        if (interactionWhatList) {
          return this.whatList[scenarioId].find((item) => item.objectId === whatObject.objectId) ? true : false;
        }
      }
      return false;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Check if Exits in What List for Scenario ID : ' + scenarioId + ', What Object : ' + JSON.stringify(whatObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private whoListContains(whoObject: IActivityDetails, scenarioId: string): boolean {
    try {
      if (scenarioId) {
        const interactionWhoList = this.whoList[scenarioId];
        if (interactionWhoList) {
          return this.whoList[scenarioId].find((item) => item.objectId === whoObject.objectId) ? true : false;
        }
      }
      return false;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Check if Exits in Who List for Scenario ID : ' + scenarioId + ', Who Object : ' + JSON.stringify(whoObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setWhatList(activityDetails: IActivityDetails, scenarioId: string) {
    try {
      const interactionWhatList = this.whatList[scenarioId];
      if (!interactionWhatList) {
        this.whatList[scenarioId] = [];
      }
      this.whatList[scenarioId].push(activityDetails);
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set What List for Scenario ID : ' + scenarioId + ', Activity Details : ' + JSON.stringify(activityDetails) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setWhoList(activityDetails: IActivityDetails, scenarioId: string) {
    try {
      const interactionWhoList = this.whoList[scenarioId];
      if (!interactionWhoList) {
        this.whoList[scenarioId] = [];
      }
      this.whoList[scenarioId].push(activityDetails);
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set Who List for Scenario ID : ' + scenarioId + ', Activity Details : ' + JSON.stringify(activityDetails) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private clearWhatList(scenarioId: string) {
    try {
      delete this.whatList[scenarioId];
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Clear What List for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private clearWhoList(scenarioId: string) {
    try {
      delete this.whoList[scenarioId];
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Clear Who List for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateRecentWorkItem(recentWorkItem: Object, scenarioId: string, activityLayout: Object) {
    try {
      if (!this.whoListContains(recentWorkItem['WhoObject'], scenarioId) && recentWorkItem['WhoObject'].objectId) {
        this.setWhoList(recentWorkItem['WhoObject'], scenarioId);
      }
      this.selectedWhoValueList[scenarioId] = recentWorkItem['WhoObject'].objectId;
      // ? recentWorkItem['WhoObject'].objectId
      // : 'UserSelectedForEmptyRecord';
      // this.selectedWhoValueList[scenarioId] = recentWorkItem['WhoObject']
      //   .objectId
      //   ? recentWorkItem['WhoObject'].objectId
      //   : 'UserSelectedForEmptyRecord';
      if (!this.whatListContains(recentWorkItem['WhatObject'], scenarioId) && recentWorkItem['WhatObject'].objectId) {
        this.setWhatList(recentWorkItem['WhatObject'], scenarioId);
      }
      this.selectedWhatValueList[scenarioId] = recentWorkItem['WhatObject'].objectId;
      // ? recentWorkItem['WhatObject'].objectId
      // : 'UserSelectedForEmptyRecord';
      // this.selectedWhatValueList[scenarioId] = recentWorkItem['WhatObject']
      //   .objectId
      //   ? recentWorkItem['WhatObject'].objectId
      //   : 'UserSelectedForEmptyRecord';
      const activity = this.getActivity(scenarioId);
      const refFields: string[] = activityLayout[activity.ChannelType]['Fields'];
      const lookupFields: string[] = activityLayout[activity.ChannelType]['LookupFields'];
      for (let field of refFields) {
        if (lookupFields[field]) {
          field = lookupFields[field];
        }
        this.setActivityField(scenarioId, field, recentWorkItem[field]);
      }
      this.updateActivityFields(scenarioId);
      this.compareActivityFields(scenarioId);
    } catch (error) {
      this.loggerService.logger.logError(
        'Salesforce - Storage : ERROR : Update Recent Work Items for Scenario ID : ' +
          scenarioId +
          ', Recent Work Item : ' +
          JSON.stringify(recentWorkItem) +
          ', Activity Layout : ' +
          JSON.stringify(activityLayout) +
          '. Error Information : ' +
          JSON.stringify(error)
      );
    }
  }

  public updateWhoWhatLists(activityObject: IActivityDetails, scenarioId: string) {
    try {
      if (this.nameObjects.indexOf(activityObject.objectType) > -1) {
        if (!this.whoListContains(activityObject, scenarioId)) {
          this.setWhoList(activityObject, scenarioId);
        }

        if (this.activeScenarioIdList.indexOf(scenarioId) >= 0) {
          if (this.nameChangesList.indexOf(scenarioId) < 0) {
            this.selectedWhoValueList[scenarioId] = activityObject.objectId;
            this.setActivityWhoObject(activityObject, scenarioId);

            if (activityObject.objectType.toUpperCase() === 'LEAD') {
              this.selectedWhatValueList[scenarioId] = this.emptyIActivityDetails.objectId;

              this.setActivityWhatObject(this.emptyIActivityDetails, scenarioId);
            }
          }
        }
      } else {
        if (!this.whatListContains(activityObject, scenarioId)) {
          this.setWhatList(activityObject, scenarioId);
        }

        if (this.activeScenarioIdList.indexOf(scenarioId) >= 0) {
          if (this.relatedToChangesList.indexOf(scenarioId) < 0) {
            this.selectedWhatValueList[scenarioId] = activityObject.objectId;
            this.setActivityWhatObject(activityObject, scenarioId);
          }
        }
      }

      this.compareActivityFields(scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Update Who What Lists for Scenario ID : ' + scenarioId + ', Activity Object : ' + JSON.stringify(activityObject) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public setsearchRecordList(searchRecords: IRecordItem[], scenarioId: string) {
    try {
      this.searchRecordList[scenarioId] = searchRecords;
      if (searchRecords.length > 1) {
        this.selectedSearchRecordList[scenarioId] = 'DefaultMultiMatch';
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Set Search Record List for Scenario ID : ' + scenarioId + ', Search Records : ' + JSON.stringify(searchRecords) + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private clearSearchRecordList(scenarioId: string) {
    try {
      delete this.selectedSearchRecordList[scenarioId];
      delete this.searchRecordList[scenarioId];
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Clear Search Record List for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private clearScenarioCadMap(scenarioId: string) {
    try {
      delete this.scenarioToCADMap[scenarioId];
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Clear Scenario CAD Map for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateActivityFields(scenarioId: string) {
    try {
      const activityFields = this.getActivityFields(scenarioId);
      if (!this.savedActivityFields) {
        this.savedActivityFields = {};
      }
      this.savedActivityFields[scenarioId] = activityFields;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Update Activity Fields for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private getActivityFields(scenarioId: string): IActivityFields {
    try {
      const activityFields: IActivityFields = {
        whoId: this.selectedWhoValueList[scenarioId] ? this.selectedWhoValueList[scenarioId] : this.activityList[scenarioId].WhoObject.objectId,
        whatId: this.selectedWhatValueList[scenarioId] ? this.selectedWhatValueList[scenarioId] : this.activityList[scenarioId].WhatObject.objectId,
        subject: this.getActivity(scenarioId).Subject,
        description: this.getActivity(scenarioId).Description
      };
      return activityFields;
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Get Activity Fields for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public removeRecentActivity(scenarioId: string) {
    try {
      if (this.recentScenarioIdList.indexOf(scenarioId) !== -1) {
        this.log(LOG_LEVEL.Debug, 'removeRecentActivity', 'Removing recent activity.', scenarioId);
        const index = this.recentScenarioIdList.indexOf(scenarioId);
        this.recentScenarioIdList.splice(index, 1);
        this.clearWhatList(scenarioId);
        this.clearWhoList(scenarioId);
        delete this.savedActivityFields[scenarioId];
        delete this.activityList[scenarioId];
        this.storeToLocalStorage();
      }
    } catch (error) {
      this.loggerService.logger.logError('SalesForce - Storage : ERROR : Remove Recent Activity for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public compareActivityFields(scenarioId: string) {
    try {
      const latestActivityData = this.getActivityFields(scenarioId);
      const keys = Object.keys(this.savedActivityFields[scenarioId]);
      for (const key in keys) {
        if (this.savedActivityFields[scenarioId][keys[key]] !== latestActivityData[keys[key]]) {
          this.activityList[scenarioId].IsUnSaved = true;
          this.storeToLocalStorage();
          return;
        }
      }
      this.activityList[scenarioId].IsUnSaved = false;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Compare Activity Fields for Scenario ID : ' + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  private storeToLocalStorage() {
    try {
      const prevScenarioRecord = localStorage.getItem('scenario');
      this.checkForDuplicateActivities(JSON.parse(prevScenarioRecord));
      const scenarioRecord = JSON.stringify({
        activityList: this.activityList,
        currentScenarioId: this.currentScenarioId,
        currentScenarioCallType: this.currentScenarioCallType,
        searchRecordList: this.searchRecordList,
        whatList: this.whatList,
        whoList: this.whoList,
        recentScenarioIdList: this.recentScenarioIdList,
        activeScenarioIdList: this.activeScenarioIdList,
        expiredScenarioIdList: this.expiredScenarioIdList,
        lstRelatedToChanges: this.relatedToChangesList,
        lstNameChanges: this.nameChangesList,
        selectedWhatValueList: this.selectedWhatValueList,
        selectedWhoValueList: this.selectedWhoValueList,
        selectedSearchRecordList: this.selectedSearchRecordList,
        currentTicketId: this.currentTicketId,
        scenarioToCADMap: this.scenarioToCADMap,
        savedActivityFields: this.savedActivityFields,
        currentTimer: this.currentTimer,
        tabbedActivityList: this.tabbedActivityList,
        tabsObject: this.tabsObject
      });

      localStorage.setItem('scenario', scenarioRecord);

      this.log(LOG_LEVEL.Trace, 'storeToLocalStorage', 'Previous local storage.', JSON.parse(prevScenarioRecord));
      this.log(LOG_LEVEL.Debug, 'storeToLocalStorage', 'Stored to local storage.', JSON.parse(scenarioRecord));
    } catch (error) {
      this.log(LOG_LEVEL.Error, 'storeToLocalStorage', 'Failed to store to local storage.', error);
    }
  }

  private checkForDuplicateActivities(previousScenarioRecord) {
    try {
      this.loggerService.logger.logDebug(`Salesforce - Storage : Checking for Duplicate active activites.\nthis.activityList: ${JSON.stringify(this.activityList)}\nLocal storage: ${JSON.stringify(previousScenarioRecord)}`);
      if (previousScenarioRecord != null && previousScenarioRecord['activityList'] != null && Object.keys(previousScenarioRecord['activityList']).length !== 0) {
        const previousActivityList = previousScenarioRecord['activityList'];
        const previousActivityListKeys = Object.keys(previousActivityList);
        const incomingActivityListKeys = Object.keys(this.activityList);
        for (let i = 0; i < previousActivityListKeys.length; i++) {
          for (let j = 0; j < incomingActivityListKeys.length; j++) {
            if (previousActivityListKeys[i] === incomingActivityListKeys[j]) {
              const previousActivity: IActivity = previousActivityList[previousActivityListKeys[i]];
              const previous = new Date(previousActivity['LastUpdated']);
              const incomingActivity: IActivity = this.activityList[incomingActivityListKeys[j]];
              const incoming = new Date(incomingActivity['LastUpdated']);
              if (incomingActivity.IsActive) {
                this.loggerService.logger.logDebug(`Salesforce - Storage : Found a match\nPrevious: ${previous}\nIncoming: ${incoming}`);
                if (previous > incoming) {
                  this.loggerService.logger.logInformation('Salesforce - Storage : Overriding activity in Storage service with activity from Local Storage.' + `Activity: ${JSON.stringify(previousActivity)}`);
                  this.activityList[incomingActivityListKeys[j]] = previousActivity;
                }
              }
            }
          }
        }
        this.loggerService.logger.logInformation(`Salesforce - Storage : Updated Activity list before save: ${JSON.stringify(this.activityList)}`);
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : Failed to compare activity lists before save. Error Information : ' + JSON.stringify(error));
    }
  }

  public syncWithLocalStorage() {
    try {
      const scenarioRecord = localStorage.getItem('scenario');
      this.log(LOG_LEVEL.Debug, 'syncWithLocalStorage', 'Syncing with local storage.', JSON.parse(scenarioRecord));
      const browserStorage = JSON.parse(scenarioRecord);
      if (browserStorage) {
        this.activityList = browserStorage.activityList;
        this.currentScenarioId = browserStorage.currentScenarioId;
        this.currentScenarioCallType = browserStorage.currentScenarioCallType;
        this.searchRecordList = browserStorage.searchRecordList;
        this.whatList = browserStorage.whatList;
        this.whoList = browserStorage.whoList;
        this.recentScenarioIdList = browserStorage.recentScenarioIdList;
        this.activeScenarioIdList = browserStorage.activeScenarioIdList;
        this.expiredScenarioIdList = browserStorage.expiredScenarioIdList;
        this.relatedToChangesList = browserStorage.lstRelatedToChanges;
        this.nameChangesList = browserStorage.lstNameChanges;
        this.selectedWhatValueList = browserStorage.selectedWhatValueList;
        this.selectedWhoValueList = browserStorage.selectedWhoValueList;
        this.selectedSearchRecordList = browserStorage.selectedSearchRecordList;
        this.currentTicketId = browserStorage.currentTicketId;
        this.scenarioToCADMap = browserStorage.scenarioToCADMap;
        this.savedActivityFields = browserStorage.savedActivityFields;
        this.currentTimer = browserStorage.currentActivity;

        if (browserStorage.tabbedActivityList) {
          this.tabbedActivityList = browserStorage.tabbedActivityList;
        }

        if (browserStorage.tabsObject) {
          this.tabsObject = browserStorage.tabsObject;
        }
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, 'syncWithLocalStorage', 'Failed to sync with local storage.', error);
    }
  }

  public onInteractionDisconnect(scenarioId: string, isAutoSave: boolean) {
    try {
      this.loggerService.logger.logDebug('Salesforce - Storage : Received Interaction Disconnect Event for Scenario ID : ' + scenarioId);
      this.loggerService.logger.logDebug('Salesforce - Storage : Removing Activity for Scenario ID : ' + scenarioId);
      this.removeActivity(scenarioId, isAutoSave);
      this.nameChangesList = this.nameChangesList.filter((item) => item !== scenarioId);
      this.relatedToChangesList = this.relatedToChangesList.filter((item) => item !== scenarioId);
      this.clearSearchRecordList(scenarioId);
      this.clearScenarioCadMap(scenarioId);
      if (this.currentScenarioId === scenarioId) {
        if (this.activeScenarioIdList.length > 0) {
          this.setCurrentScenarioId(this.activeScenarioIdList[0]);
        } else {
          this.setCurrentScenarioId(null);
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('Salesforce - Storage : ERROR : On Interaction Disconnect. Error Information : ' + JSON.stringify(error));
    }
  }

  public getCadFields(scenarioId?: string): { [key: string]: IField } {
    return this.scenarioToCADMap[scenarioId || this.currentScenarioId];
  }

  public updateCadFields(interaction: IInteraction, cadActivityMap: Object) {
    try {
      const isInteractionCurrent = this.activityListContains(interaction.scenarioId);
      const isInteractionRecent = this.recentActivityListContains(interaction.scenarioId);
      if (interaction.details && interaction.details.fields && !this.scenarioToCADMap[this.currentScenarioId]) {
        this.scenarioToCADMap[interaction.scenarioId] = interaction.details.fields;
        this.storeToLocalStorage();
      }
      if (isInteractionCurrent || isInteractionRecent) {
        if (interaction.details) {
          for (const key in cadActivityMap) {
            if (interaction.details.fields[key] || interaction[key]) {
              const objActivity = this.getActivity(interaction.scenarioId);
              if (!objActivity.CadFields) {
                objActivity.CadFields = {};
              }
              objActivity.CadFields[cadActivityMap[key]] = interaction.details.fields[key] ? interaction.details.fields[key].Value : interaction[key];
              this.updateActivity(objActivity);
            }
          }
        }
      }
    } catch (error) {
      this.loggerService.logger.logError(
        'Salesforce - Storage : ERROR : Updating CAD Fields for Scenario ID : ' +
          interaction.scenarioId +
          ', Interaction Details : ' +
          JSON.stringify(interaction) +
          ', CAD Activity Map : ' +
          JSON.stringify(cadActivityMap) +
          '. Error information : ' +
          JSON.stringify(error)
      );
    }
  }
}
